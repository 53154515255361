import { FormContext } from '@sw-sw/lib-form';
import { Question, QuestionGroup } from '@sw-sw/lib-inspection-templates';
import { BaseTemplate } from '@sw-sw/ui-inspection-templates';
import { get, isNull } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { InspectionContext } from '../../../../contexts/InspectionContext';
import { ProjectContext } from '../../../../contexts/ProjectContext';
import ProjectPermissionContext from '../../../../contexts/ProjectPermissionContext';
import { QuestionCommentContext } from '../../../../contexts/QuestionCommentContext';
import { QuestionTypeContext } from '../../../../contexts/QuestionTypeContext';
import DashboardContext from '../../../../contexts/DashboardContext';
import { useTemplateUserData } from '../../../../hooks/templateUserData';
import { templateQuestionConfigApi } from '../../../../utils/api/templateQuestionConfig';
import { InspectionApiErrorType } from '../../Details/InspectionQuestions';
import ToastError from '../../../Shared/ToastError/ToastError';
import { AnyTemplateLayout } from '../AnyTemplateLayout';
export const DynamicBaseTemplate = () => {
  const formContext = useContext(FormContext);
  const inspectionContext = useContext(InspectionContext);
  const questionTypeContext = useContext(QuestionTypeContext);
  const questionCommentContext = useContext(QuestionCommentContext);
  const projectContext = useContext(ProjectContext);
  const projectPermissionContext = useContext(ProjectPermissionContext);
  const { fetchStatsFuncForCal } = useContext(DashboardContext);
  const changed = useRef(false);
  const [busy, setBusy] = useState(false);
  const userData = useTemplateUserData();
  const questionConfigQuery = useQuery({
    queryFn: () =>
      templateQuestionConfigApi.index(inspectionContext.template.id),
    refetchOnWindowFocus: false,
  });
  const groups: (QuestionGroup & {
    questions: Question[];
  })[] = inspectionContext.template.question_groups.filter(
    ({ name }: { name: string }) => name !== 'Condition Group',
  );

  const history = useHistory();

  /**
   * Store form value in a reference. This is necessary for the unmount effect to work correctly.
   */
  const formValueRef = useRef(formContext.value);
  useEffect(() => {
    formValueRef.current = formContext.value;
  }, [formContext.value]);

  const onSave = async (source:string='save') => {
    if (busy) {
      return;
    }

    setBusy(true);
    const toastId = toast('Saving inspection');

    // formValueRef.current = { savingSource: source, ...formValueRef.current };

    const response = await inspectionContext
      .onSave(formValueRef.current, true)
      .catch(async (err: InspectionApiErrorType) => {
        changed.current = false;
        await inspectionContext.reloadInspection();
        inspectionContext.resetTemplate();

        toast.update(toastId, {
          render: (
            <ToastError
              message='Cannot save inspection because of error: '
              error={inspectionContext.setSaveError(err)}
            />
          ),
          autoClose: false,
          type: 'error',
        });
      });
      
    if (response) {
      inspectionContext.setInspTempAnswerSaveResponse({...response.templateUnanswered});
      inspectionContext.setTemplate({
        ...inspectionContext.template,
        question_groups: response.template.question_groups,
      });

      let hasTemplateUnanswered = false;

      if(response.templateUnanswered && response.templateUnanswered.status != "complete" && response.templateUnanswered.unansweredAndRequired && response.templateUnanswered.unansweredAndRequired.length > 0) {
        hasTemplateUnanswered = true;
        toast.update(toastId, {
          render: "Inspection is saved! But need to answer all required questions marked in red to continue!",
          type: "warning",
        });
      }else{
        toast.update(toastId, {
          render: "Inspection saved!",
          type: "success",
        });
      }
      
      if(source === "saveAndContinue" && !hasTemplateUnanswered){
        history.push(`/inspection/${response.inspection.id}/findings`);
      }

      fetchStatsFuncForCal()
    }

    setBusy(false);
    changed.current = false;
  };

  const onSaveAndContinueClick = () => {
    onSave('saveAndContinue');
  }

  /** Save on unmount, if data has changed */
  useEffect(() => {
    return () => {
      if (changed.current) {
        onSave('save');
      }
    };
  }, []);


  return (
    <AnyTemplateLayout
      templateName={inspectionContext.template.name}
      onSave={()=>onSave('save')}
      busy={busy}
      isReadOnly={
        projectPermissionContext.readOnly ||
        (!isNull(inspectionContext.inspection.certification_date) &&
          (projectContext.project!.inspection_compliance_required
            ? !isNull(inspectionContext.inspection.compliance_date)
            : true))
      }
      onSaveAndContinue={onSaveAndContinueClick}
    > 
      <BaseTemplate
        options={{readOnly: false}}
        state={{ busy }}
        userData={userData}
        data={{
          groups,
          groupQuestions: groups.reduce<Record<number, Question[]>>(
            (gq, group) => {
              if (group.id) {
                gq[group.id] = group.questions;
              }

              return gq;
            },
            {},
          ),
          inspectionId: inspectionContext.inspection.id,
          questionTypes: questionTypeContext.types,
          questionOptions: {},
          questionConfig: questionConfigQuery.isFetched
            ? questionConfigQuery.data || {}
            : {},
          }}
        inspTempAnswerSaveResponse={inspectionContext.inspTempAnswerSaveResponse}
        controller={{
          onSave,
          onCommentDelete: ({ questionId, inspectionId }) => {
            questionCommentContext.onDelete({ inspectionId, questionId });
          },
          onCommentSave: ({ questionId, inspectionId, content: value }) => {
            questionCommentContext.onSave({ questionId, value, inspectionId });
          },

          onInputChange: (id: number, value: any) => {            
            console.log(
              'changed',
              id,
              value,
              get(formContext.value, `question-${id}`),
              formContext.value,
            );
            if (!changed.current) {
              changed.current = true;
            }
            formContext.set(`question-${id}`, value);
          },
        }}
      />
    </AnyTemplateLayout>
  );
};
