import {
  CertificationIndexResponse,
  CreateApiParam,
  SignatureGroupType,
} from "@sw-sw/lib-certification";
import xhrService from "../xhrService";
import { baseUrl as baseInspectionUrl } from "./inspection";

export type BulkCertifyParam = { id: number; date: string; lineType: string }[];

export const certificationApi = {
  /**
   * @todo argument signatureLineId: string
   * @todo new api `${baseInspectionUrl}/${inspectionId}/signature/${signatureLineId}`
   */
  create: ({ inspectionId, group, ...data }: CreateApiParam, resend?: boolean) => {

    return xhrService.post<void>(
      `${baseInspectionUrl}/${inspectionId}/cert/${group}`,
      {data, resend},
    );
  },
  index: (inspectionId: number) => {
    return xhrService
      .get<CertificationIndexResponse>(`/api/inspections/${inspectionId}/cert`)
      .then(res => res.data);
  },

  bulkCertify: (data: BulkCertifyParam) => {    
    return xhrService.post(
      `${baseInspectionUrl}/cert/${SignatureGroupType.compliance}`,
      data,
    );
  },
};
