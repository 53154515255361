import React, { useEffect, useRef, useState } from "react";
import { QueryClient, QueryObserverResult, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import {
  PaginationContextValue,
  usePagination,
} from "../contexts/PaginationContext";
import { SearchState, useSearch } from "../contexts/SearchContext";
import {
  IApiQueryResource,
  QueryDefaults,
  useApiQuery,
  extractData,
} from "./apiQuery";
import useSelection, { SelectionState } from "./selection";

export type ResourceHook<D = any> = {
  client: QueryClient;
  data: D[];
  /**
   * Use this loading variable for checking completion as opposed
   * to query.isSuccess or query.isLoading, as there are conditions
   * where these will be updated before the data variable.
   *  */
  loading: boolean;
  pagination: PaginationContextValue;
  query: QueryObserverResult;
  search: SearchState;
  selection: SelectionState;
  setData: React.Dispatch<D[]>;
};

export type ResourceHookProps<D = any> = {
  /**
   * Resource name. See {utils/apiResources}
   */
  resource: string;
  /**
   * Disable Pagination. Will also enable local search
   */
  disablePagination?: boolean;

  /** Optional division id */
  divisionId?: number;

  /** useQuery default options */
  queryDefaults?: QueryDefaults<D>;
};

export function useResource<D extends IApiQueryResource = IApiQueryResource>({
  resource,
  disablePagination,
  divisionId,
  queryDefaults,
}: ResourceHookProps<D>): ResourceHook<D> {
  const location = useLocation();
  const urlQuery = useRef(new URLSearchParams(location.search));
  const pagination = usePagination();
  const search = useSearch({
    initialValue: urlQuery.current.get("search") || undefined,
  });
  const client = useQueryClient();
  const getPageParam = window.location.search.split("page=")[1]  
  const { data: queryData, query } = useApiQuery({
    resource,
    divisionId,
    page: disablePagination ? null : Number(getPageParam) || 1,
    setPage: pagination.setPage,
    setPaginationState: pagination.setPaginationState,
    search: search.query,
    queryDefaults: queryDefaults,
  });

  /**
   * Keep local copy of data (required for local search)
   */
  const [data, setData] = useState(queryData ? (queryData as D[]) : []);
  const [loading, setLoading] = useState(query.isLoading);
  const selection = useSelection({ total: data.length });

  useEffect(() => keepLocalData({ query, setData, setLoading }), [query]);
  useEffect(
    () =>
      resetDataOnEmptySearch({
        disablePagination,
        queryData: queryData,
        searchQuery: search.query,
        setData,
      }),
    [search],
  );
  useEffect(() => resetSelection(selection), [pagination.page, search.query]);

  return {
    pagination,
    search,
    client,
    query,
    data,
    loading,
    setData,
    selection,
  };
}

export default useResource;

function keepLocalData({
  query,
  setData,
  setLoading,
}: {
  query: QueryObserverResult<any>;
  setData: React.Dispatch<any[]>;
  setLoading: React.Dispatch<boolean>;
}) {
  if (query.data) {
    setData(extractData(query.data) || []);
  }
  setLoading(query.isLoading);
}

function resetDataOnEmptySearch({
  searchQuery,
  setData,
  queryData,
  disablePagination,
}: {
  searchQuery: string;
  setData: React.Dispatch<any[]>;
  queryData?: any[] | null;
  disablePagination?: boolean;
}) {
  if (disablePagination && queryData && !searchQuery) {
    setData(extractData(queryData) || []);
  }
}

function resetSelection({ selected, setSelected }: SelectionState) {
  if (selected) {
    setSelected([]);
  }
}
