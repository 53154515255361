import React, { useState, useRef } from "react";
import { faTimes, faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const NewClientDocTypeFolder = (props) => {
    const { newDocTypes, setNewDocTypes } = props
    const [createFolderModal, setCreateFolderModal] = useState(false)
    const [newDocInputState, setNewDocInputState] = useState("")
    const inputRef = useRef(null)

    const handleCreateFolder = () => {
        setCreateFolderModal(true)
        inputRef.current.value = ""
    }
    
    const handleAddNewDocType = () => {
        setNewDocTypes([...newDocTypes, newDocInputState])
        setCreateFolderModal(false)
    }
    
    const handleRemoveFolderFromList = (index) => {
        const filteredList = newDocTypes.filter((ele, i) => i !== index)

        setNewDocTypes(filteredList)
    }

    return(
        <div>
            <ul>
            {
                newDocTypes.length ? newDocTypes.map((ele, index) => {
                    return (
                    <div className="ClientDetailsFormUI__new-folder-list">
                        <li key={index}>{ele}</li>
                        <span onClick={() => handleRemoveFolderFromList(index)}><FontAwesomeIcon icon={faTrashAlt} color="red"/></span>
                    </div>
                    )
                }) : <></>
            }
            </ul>
            
            <button onClick={handleCreateFolder} style={{ display: createFolderModal ? "none" : "block" }}>Create New Folder +</button>

            <div className="ClientDetailsFormUI__new-folder-input-container" style={{ display: createFolderModal ? "flex" : "none"}}>
            <input type="text" ref={inputRef} onChange={(e) => setNewDocInputState(e.target.value)}/>
            <button className="ClientDetailsFormUI__new-folder-add-button" onClick={handleAddNewDocType}><FontAwesomeIcon icon={faPlus} /></button>
            <button onClick={() => setCreateFolderModal(false)}><FontAwesomeIcon icon={faTimes} color="red"/></button>
            </div>
        </div>
    )
}



export default NewClientDocTypeFolder