import React, { useContext, useCallback } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { FormSchemaFields } from "@sw-sw/lib-form";
import projectApi from "../../../utils/api/project";
import FormActions from "../../Shared/form/modal/FormActions";
import { FormContext } from "@sw-sw/lib-form";
import { UIControlType } from "@sw-sw/lib-form-control-types";
const schema = {
  created_date: {
    controlType: UIControlType.date,
    validation: {
      required: true,
      dateFormat: "MM-dd-yyyy",
      minDate: moment().milliseconds(0).seconds(0).minutes(0).hour(0).toDate(),
    },
  },
};

/**
 * UI to edit the next inspection date
 */
function InspectionDateFormUI({
  handleClose,
  handleSubmit,
  initialValue,
  value,
  onChange,
}) {
  return (
    <span className="inspection-date-form">
      <FormSchemaFields
        schema={schema}
        initialFormData={initialValue}
        formData={value}
        onChange={onChange}
      />
      <FormActions onCancel={handleClose} onSubmit={handleSubmit} small />
    </span>
  );
}

/** Controller for the UI above */
function InspectionDateForm({
  projectId,
  initialValue,
  handleClose,
  onSubmit,
}) {
  const formStore = useContext(FormContext);
  const handleSubmit = useCallback(
    data => projectApi.setInspectionDate(projectId, data).then(onSubmit),
    [],
  );

  return (
    <InspectionDateFormUI
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      initialValue={{
        created_date: initialValue,
      }}
      value={formStore.value}
      onChange={formStore.set}
    />
  );
}

InspectionDateForm.propTypes = {
  projectId: PropTypes.number.isRequired,
  initialValue: PropTypes.any.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default InspectionDateForm;
