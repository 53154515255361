import classnames from "classnames";
import { capitalize } from "lodash";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  ProjectContext,
  ProjectContextValue,
} from "../../../contexts/ProjectContext";
// import { isFindingsComplete, } from "../../../contexts/InspectionContext"
import { getInspectionTypeLabel } from "../../../utils";
import DataPlaceholder from "../../Shared/Loading/DataPlaceholder";
import CheckBox from "./CheckBox";
import FindingCount from "./FindingCount";
import SignatureDate from "./SignatureDate";
import { findingsNotRequiredForCompliance } from "../../Projects/Details/Inspections"
export interface IInspectionListingProps {
  checkedInspections: Array<number>;
  handleInspectionCheck: (id: number) => void;
  inspections: ProjectContextValue["inspections"];
  userCanSign: boolean;
  isPublic?: boolean;
  pathname?: string;
}

const InspectionListing: React.FC<IInspectionListingProps> = ({
  checkedInspections,
  handleInspectionCheck,
  inspections,
  userCanSign,
  // pathname = null
}) => {
  const projectStore = useContext(ProjectContext);
  const isPublic = window.location.pathname.startsWith("/public")

  return (
    <div className="inspection-listing flex-row">
      {inspections.map((inspection, i) => {
        const {
          status,
          id,
          certification_date,
          compliance_date,
          findingCount,
          inspection_template,
          acknowledgement_date,
          findings
        } = inspection;

        return (
          <div className="inspection flex-item" key={id}>
            {userCanSign &&
              projectStore.project &&
              projectStore.project.inspection_compliance_required === true &&
              certification_date &&
              (inspection_template && inspection_template.has_compliance_cert) &&
              !compliance_date &&
              findingsNotRequiredForCompliance(inspection_template, findings) &&
              status === "complete" ? (
              <CheckBox
                handleClick={() => handleInspectionCheck(id)}
                isChecked={checkedInspections.includes(id)}
              />
            ) : null}

            <Link
              className="inspection-details"
              to={isPublic ? `/public/inspection/${id}/questionnaire` : `/inspection/${id}/questionnaire`}
            >
              <h2>
                {inspection.type ? (
                  `#${inspections.length - i} | ${getInspectionTypeLabel(inspection)}`
                ) : (
                  <DataPlaceholder width={10} />
                )}
              </h2>

              <p className="questions">
                Inspection Questions |{" "}
                <span
                  className={classnames(
                    status === "complete" ? "green-light" : "red-light",
                  )}
                >
                  {status ? capitalize(status) : <DataPlaceholder />}
                </span>
              </p>

              <SignatureDate date={certification_date}>
                Certification
              </SignatureDate>

              <SignatureDate
                date={acknowledgement_date}
                disabled={
                  acknowledgement_date ? false : inspection_template && inspection_template.has_acknowledgement ? !inspection_template.has_acknowledgement : true
                }
              >
                Acknowledgement
              </SignatureDate>

              {
                (inspection && inspection.inspection_template && inspection.inspection_template.has_compliance_cert) &&
                <SignatureDate
                  date={compliance_date}
                  disabled={
                    projectStore.project !== null &&
                    !projectStore.project.inspection_compliance_required
                  }
                >
                  Compliance
                </SignatureDate>
              }

              <div className="actions">
                <FindingCount
                  count={findingCount.total.ca}
                  isComplete={
                    findingCount.total.ca === findingCount.complete.ca
                  }
                  isCA
                >
                  Corrective Actions
                </FindingCount>

                <FindingCount
                  count={findingCount.total.mi}
                  isComplete={
                    findingCount.total.mi === findingCount.complete.mi
                  }
                >
                  Maintenance Items
                </FindingCount>
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default InspectionListing;
