import React, { useEffect, useContext, useRef } from 'react';
import { getCenter } from 'ol/extent';
import { mapUtil, overlayComponents, DetailsOverlay } from '@sw-sw/common';
import { Context as DataSourceContext } from '../../DataSourceContext';
import { Context as InteractionContext } from '../../Interaction/InteractionContext';

import useMap from '../../useMap';
import useFeature from '../../hooks/useFeature';
import { getDownloadUrl } from '../../../../utils/api/upload';
import { RolesContext } from '../../../../contexts/RolesContext';

function SourceModelDetailOverlay() {
  const overlayElement = useRef();
  const map = useMap();
  const feature = useFeature();

  /** @type {import('ol').Overlay} */
  const overlay = mapUtil.getOverlay();

  const dataSourceContext = useContext(DataSourceContext);
  const interactionContext = useContext(InteractionContext);
const permCheck = useContext(RolesContext).userHasPermission
const canCloseFinding = permCheck("update", "Close Findings")
const canDelete = permCheck("delete", "Findings");

  useEffect(() => {
    if (overlay && overlayElement.current) {
      const center = getCenter(feature.getGeometry().getExtent());

      overlay.setElement(overlayElement.current);
      overlay.setPosition(center);

      map.addOverlay(overlay);
    }

    return () => {
      map.removeOverlay(overlay);
    };
  }, [feature]);

  let OverlayContent, sourceModel;

  const positionableType = feature.get('positionable_type');
  const overlayContentProps = {
    images: [],
    readOnly:
      dataSourceContext.isReadOnlyDataType(positionableType) ||
      interactionContext.modeData.readOnly,
    canDelete: canDelete,
    canCloseFinding: canCloseFinding
  };

  if (feature && overlayComponents[positionableType]) {
    OverlayContent = overlayComponents[positionableType];
    sourceModel = dataSourceContext.getById(
      feature.get('positionable_source_id'),
      positionableType,
    );

    Object.assign(overlayContentProps, {
      images: (sourceModel.finding_uploads || []).map(
        ({ id, updated_at, upload }) => ({
          id,
          updated_at,
          imageURL: getDownloadUrl(upload.GUID),
        }),
      ),
    });
    if (
      positionableType === 'findings' && !overlayContentProps.readOnly) {
      // set image urls and event handlers
      Object.assign(overlayContentProps, {
        handleDeleteClick: () => {
          // set the types to findings
          interactionContext.setPositionableType('findings');
          // then select the finding
          interactionContext.setMode('deleteSourceModel', {
            sourceModelIndex: sourceModel.index,
          });
        },
        handleEditClick: (e, commentOnly = false) => {
          // set the types to findings
          interactionContext.setPositionableType('findings');
          // then select the finding
          interactionContext.setMode('editSourceModel', {
            sourceModelIndex: sourceModel.index,
            nextMode: 'detail',
            feature,
            props: { commentOnly },
          });
        },
      });
    }
  }

  return (
    <div className="hidden">
      <DetailsOverlay ref={overlayElement}>
        <OverlayContent {...sourceModel} {...overlayContentProps} />
      </DetailsOverlay>
    </div>
  );
}

export default SourceModelDetailOverlay;
