import { InspectionTemplate } from "@sw-sw/lib-inspection-templates";
import { uniq } from "lodash";
import moment from "moment";
import xhrService from "../xhrService";

const baseUrl = `/api/divisions`;

interface Division {
  id: number;
  name: string;
  createdAt?: Date;
}

export interface DivisionUser {
  userId: number;
  divisionId: number;
  user: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
  };
}

export type IndexApi<D = any[]> = (
  divisionId: number,
  pageNumber?: number,
  searchQuery?: string,
  resourceIds?: number[],
) => Promise<D>;
export type DeleteApi = (id: number, data: number[]) => Promise<void>;

export type CreateApi<A = any, B = any> = (id: number, data: A) => Promise<B>;

export type ArchiveApi = (divisionId: number, id: number) => Promise<any>;

type DivisionApi = {
  index: () => Promise<Division[]>;
  create: (data: any) => Promise<Division>;
  get: (id: number) => Promise<Division>;
  destroy: (id: number) => Promise<any>;
  update: (id: number, data: any) => Promise<Division>;
  users: {
    // add search and pagination that can be found in userApi#index
    index: IndexApi;
    create: CreateApi<{ users: { id: number }[] }>;
    delete: DeleteApi;
  };
  clients: {
    index: IndexApi;
    create: CreateApi<{ clients: { id: number }[] }>;
    delete: DeleteApi;
  };
  regulations: {
    index: IndexApi;
    create: CreateApi<{ regulations: { id: number }[] }>;
    delete: DeleteApi;
  };
  inspectionTemplates: {
    index: IndexApi<InspectionTemplate[]>;
    create: CreateApi<{ inspectionTemplates: { id: number }[] }>;
    delete: DeleteApi;
  };
  projects: {
    index: IndexApi;
    archive: {
      index: IndexApi;
      get: ArchiveApi;
      archive: ArchiveApi;
      restore: ArchiveApi;
    };
  };
};

const divisionApi: DivisionApi = {
  index: () =>
    xhrService.get(baseUrl).then(res => {
      return res.data.map((division: Division) => {
        return {
          ...division,
          createdAt: moment(division.createdAt).format("MM-DD-YYYY"),
        };
      });
    }),
  create: data => xhrService.post(`${baseUrl}`, data).then(res => res.data),
  get: id =>
    xhrService
      .get(`${baseUrl}/${id}`)
      .then(res => res.data)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      }),
  destroy: id =>
    xhrService
      .delete(`${baseUrl}/${id}`)
      .then(() => true)
      .catch(({ response }) => {
        throw new Error(response.data.message);
      }),
  update: (id, data) =>
    xhrService.put(`${baseUrl}/${id}`, data).then(res => res.data),
  users: {
    index: (id, pageNumber?: number, searchQuery?: string) => {
      const params: { s?: string; p?: number } = {};

      if (searchQuery) {
        params.s = searchQuery;
      }

      if (pageNumber) {
        params.p = pageNumber;
      }

      return xhrService
        .get(`${baseUrl}/${id}/users`, { params })
        .then(handlePaginatedResponse.bind(null, "user"));
    },
    create: (id, data) => {
      const userIds = data.users.map((user: { id: number }) => user.id);

      return xhrService
        .post(`${baseUrl}/${id}/users`, { userIds: userIds })
        .then(res => res.data);
    },
    delete: (id, data) => {
      return xhrService
        .delete(`${baseUrl}/${id}/users`, { data: { userIds: data } })
        .then(res => res.data);
    },
  },
  clients: {
    index: (
      id,
      pageNumber?: number,
      searchQuery?: string,
      resourceIds?: number[],
    ) => {
      const params: { s?: string; p?: number; clientIds?: number[] } = {};

      if (searchQuery) {
        params.s = searchQuery;
      }

      if (pageNumber) {
        params.p = pageNumber;
      }

      if (resourceIds) {
        params.clientIds = uniq(resourceIds);
      }

      return xhrService
        .get(`${baseUrl}/${id}/clients`)
        .then(handlePaginatedResponse.bind(null, "client"));
    },
    create: (id, data) => {
      const clientIds = data.clients.map((client: { id: number }) => client.id);

      return xhrService
        .post(`${baseUrl}/${id}/clients`, { clientIds: clientIds })
        .then(res => res.data);
    },
    delete: (id, data) => {
      return xhrService
        .delete(`${baseUrl}/${id}/clients`, { data: { clientIds: data } })
        .then(res => res.data);
    },
  },
  regulations: {
    index: (id, pageNumber?: number, searchQuery?: string) => {
      const params: { s?: string; p?: number } = {};

      if (searchQuery) {
        params.s = searchQuery;
      }

      if (pageNumber) {
        params.p = pageNumber;
      }

      return xhrService
        .get(`${baseUrl}/${id}/regulations`, { params })
        .then(handlePaginatedResponse.bind(null, "documentGroup"));
    },
    create: (id, data) => {
      const regulationIds = data.regulations.map(
        (reg: { id: number }) => reg.id,
      );

      return xhrService
        .post(`${baseUrl}/${id}/regulations`, { regulationIds: regulationIds })
        .then(res => res.data);
    },
    delete: (id, data) => {
      return xhrService
        .delete(`${baseUrl}/${id}/regulations`, {
          data: { regulationIds: data },
        })
        .then(res => res.data);
    },
  },
  inspectionTemplates: {
    index: id => {
      return xhrService
        .get(`${baseUrl}/${id}/inspectionTemplates`)
        .then(res =>
          res.data.map(
            (dit: { inspectionTemplate: any }) => dit.inspectionTemplate,
          ),
        );
    },
    create: (id, data) => {
      const inspectionTemplateIds = data.inspectionTemplates.map(
        (inspTemp: { id: number }) => inspTemp.id,
      );

      return xhrService
        .post(`${baseUrl}/${id}/inspectionTemplates`, {
          inspectionTemplateIds: inspectionTemplateIds,
        })
        .then(res => res.data);
    },
    delete: (id, data) => {
      return xhrService
        .delete(`${baseUrl}/${id}/inspectionTemplates`, {
          data: { inspectionTemplateIds: data },
        })
        .then(res => res.data);
    },
  },
  projects: {
    index: (id, pageNumber?: number, searchQuery?: string) => {
      const params: { s?: string; p?: number } = {};

      if (searchQuery) {
        params.s = searchQuery;
      }

      if (pageNumber) {
        params.p = pageNumber;
      }

      return xhrService
        .get(`${baseUrl}/${id}/projects`, { params })
        .then(handlePaginatedResponse.bind(null, "project"));
    },
    archive: {
      index: (divisionId, pageNumber?: number, searchQuery?: string) => {
        const params: { s?: string; p?: number } = {};

        if (searchQuery) {
          params.s = searchQuery;
        }

        if (pageNumber) {
          params.p = pageNumber;
        }

        return xhrService
          .get(`${baseUrl}/${divisionId}/projects/archive`, { params })
          .then(handlePaginatedResponse.bind(null, "project"));
      },
      get: (divisionId: number, id: number) => {
        return xhrService
          .get(`${baseUrl}/${divisionId}/projects/archive/${id}`)
          .then((res: { data: any }) => res.data);
      },
      archive: (divisionId: number, id: number) => {
        return xhrService
          .post(`/api/divisions/${divisionId}/projects/archive/${id}`)
          .then(res => res.data);
      },
      restore: (divisionId: number, id: number) => {
        return xhrService
          .delete(`/api/divisions/${divisionId}/projects/archive/${id}`)
          .then(res => res.data);
      },
    },
  },
};

export default divisionApi;

function handlePaginatedResponse(key: string, res: { data: any }) {
  if (Array.isArray(res.data)) {
    return res.data.map((dc: any) => dc[key]);
  }

  if (res.data.pageData) {
    return {
      ...res.data,
      pageData: res.data.pageData.map((dc: any) => dc[key]),
    };
  }

  return res.data;
}
