import React, { useEffect, useCallback, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { get } from 'lodash';

import { AppDivisionContext } from './AppDivisionContext';
import {
  useOpenFindingsData,
  useFetchAllFindingTypes,
  useTeamManagement,
  useInspectionsDueData,
  useFindingsOverviewData,
  useFetchAllFindingTypesForFO,
  useCalendarData,
  useFetchProjectsListForCal,
  useInspectionsCertificationDue,
  useMyProjectsData
} from '../hooks/customDashboard';

interface InspectionQueryParamsIFC {
  s?: string;
}

export type DashboardConextTypeValues = {
  list: Array<any>;
  setList: (input: any) => void;
  inspectionsCertDue: any;
  inspectionsCertDueQuery: any;
  inspectionsCertDueQueryParams: InspectionQueryParamsIFC;
  setinspectionsCertDueQueryParams: (input: any) => void;
  displayStatsForOF: any;
  isLoadingForOF: any;
  allProjectsForOF: any;
  findingTypeListForOF: any;
  setDisplayStatsForOF: any;
  setIsLoadingForOF: any;
  fetchStatsFuncForOF: any;
  getTeamManagementData: any;
  teamManagementData: any;
  isLoadingForTM: any;
  setIsLoadingForTM: any;
  displayStatsForID:any,
  allProjectsForID:any,
  isLoadingForID:any,
  setDisplayStatsForID:any,
  setIsLoadingForID:any,
  fetchStatsFuncForID:any,
  displayStatsForFO:any,
  allProjectsForFO:any,
  isLoadingForFO:any,
  findingTypeListForFO:any,
  setDisplayStatsForFO:any,
  setIsLoadingForFO:any,
  displayStatsForCal:any,
  isLoadingForCal:any,
  projectsListForCal:any,
  setDisplayStatsForCal:any,
  setIsLoadingForCal:any,
  fetchStatsFuncForCal:any,
  dashboardWidgetSizeHolder: any,
  setDashboardWidgetSizeHolder: any,
  displayStatsForMP:any,
  allProjectsForMP:any,
  isLoadingForMP:any,
  setDisplayStatsForMP:any,
  setIsLoadingForMP:any,
  fetchStatsFuncForMP:any
};

const DashboardContext = React.createContext<DashboardConextTypeValues>(
  null as any,
);

export const DashboardProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const routeMatch = useRouteMatch();
  const appDivisionContext = React.useContext(AppDivisionContext);
  const divisionId = get(
    routeMatch.params,
    'division_id',
    appDivisionContext.appDivisionId,
  );

  const [list, setList] = React.useState<any[]>([]); // setting list for dashboard widget items
  const [prevList, setPrevList] = React.useState<any[]>([]);

  const [loadInspectionData, setLoadInspectionData] =
    React.useState<boolean>(false);

  const [inspectionsCertDueQueryParams, setinspectionsCertDueQueryParams] =
    React.useState<InspectionQueryParamsIFC>({});
  const inspectionsCertDueQuery = useInspectionsCertificationDue(
    inspectionsCertDueQueryParams ? inspectionsCertDueQueryParams : {},
    divisionId,
    loadInspectionData,
  );

  const inspectionsCertDue = inspectionsCertDueQuery || [];

  const [dashboardWidgetSizeHolder, setDashboardWidgetSizeHolder] = useState<any>({});

  const [
    displayStatsForOF,
    allProjectsForOF,
    isLoadingForOF,
    setDisplayStatsForOF,
    setIsLoadingForOF,
    fetchStatsFuncForOF
  ] = useOpenFindingsData();

  const [
    getTeamManagementData,
    teamManagementData,
    isLoadingForTM,
    setIsLoadingForTM,
  ] = useTeamManagement();

  const [
    displayStatsForID,
    allProjectsForID,
    isLoadingForID,
    setDisplayStatsForID,
    setIsLoadingForID,
    fetchStatsFuncForID
  ] = useInspectionsDueData();

  const [
    displayStatsForFO,
    allProjectsForFO,
    isLoadingForFO,
    setDisplayStatsForFO,
    setIsLoadingForFO,
  ] = useFindingsOverviewData();

  const [
    displayStatsForCal,
    isLoadingForCal,
    setDisplayStatsForCal,
    setIsLoadingForCal,
    fetchStatsFuncForCal
  ] = useCalendarData();

  const [
    displayStatsForMP,
    allProjectsForMP,
    isLoadingForMP,
    setDisplayStatsForMP,
    setIsLoadingForMP,
    fetchStatsFuncForMP
  ] = useMyProjectsData();

  const [findingTypeListForOF] = useFetchAllFindingTypes();
  const [findingTypeListForFO] = useFetchAllFindingTypesForFO();
  const [projectsListForCal] = useFetchProjectsListForCal();

  const setWidgetApiData = useCallback(() => {
    const uniqueList = list.filter((item) => prevList.indexOf(item) == -1);

    if (list.length > 0 && uniqueList.length > 0) {
      if (list.includes('Certifications Due')) {
        setLoadInspectionData(true);
      }
    }

    setPrevList(list);
  }, [list]);

  useEffect(() => {
    setWidgetApiData();
  }, [list]);

  return (
    <DashboardContext.Provider
      value={{
        // dashboard widget common methods
        list,
        setList,

        // inspection certification due variabels
        inspectionsCertDueQuery,
        inspectionsCertDue,
        inspectionsCertDueQueryParams,
        setinspectionsCertDueQueryParams,

        // open findings
        displayStatsForOF,
        allProjectsForOF,
        isLoadingForOF,
        findingTypeListForOF,
        setDisplayStatsForOF,
        setIsLoadingForOF,
        fetchStatsFuncForOF,

        // team management
        getTeamManagementData,
        teamManagementData,
        isLoadingForTM,
        setIsLoadingForTM,

        // Inspections Due
        displayStatsForID,
        allProjectsForID,
        isLoadingForID,
        setDisplayStatsForID,
        setIsLoadingForID,
        fetchStatsFuncForID,

        // Findings Overview
        displayStatsForFO,
        allProjectsForFO,
        isLoadingForFO,
        findingTypeListForFO,
        setDisplayStatsForFO,
        setIsLoadingForFO,

         // Calendar
        displayStatsForCal,
        isLoadingForCal,
        projectsListForCal,
        setDisplayStatsForCal,
        setIsLoadingForCal,
        fetchStatsFuncForCal,

        //My Projects
        displayStatsForMP,
        allProjectsForMP,
        isLoadingForMP,
        setDisplayStatsForMP,
        setIsLoadingForMP,
        fetchStatsFuncForMP,

        // dashboard Size
        dashboardWidgetSizeHolder,
        setDashboardWidgetSizeHolder
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardContext;
