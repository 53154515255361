import { FormHelpText } from "@sw-sw/lib-form";
import { Button, DropdownMenu, LoadingIcon } from "@sw-sw/lib-ui";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import ConfirmationModal from "./ConfirmationModal/ConfirmationModal";
import { getSubmitErrorHandler } from "./form/modal/FormActions";
import { ScrollFixHOC } from "./ScrollSpy";

/**
 * Row of action buttons. Supports primary and secondary actions
 * Supports "busy" state for primary action
 */

export type ActionButtonsProps = {
  primary?: string;
  onClick?: any;
  secondary?: Array<any>;
  secondaryVisible?: boolean;
  className?: string;
  forwardedRef?: any;
  busy?: boolean;
  error?: string | null;
  primaryDisabled?: boolean;
  saveAndContinue?: boolean;
  saveAndContinueDisabled?: boolean,
  onSaveAndContinue?: Function
};

const ActionButtonsUI: React.FC<ActionButtonsProps> = ({
  primary = "",
  onClick = () => { },
  secondary = [],
  secondaryVisible = false,
  className = "",
  forwardedRef,
  busy: busyProp = undefined,
  error = null,
  primaryDisabled = false,
  saveAndContinue = false,
  saveAndContinueDisabled = false,
  onSaveAndContinue = () => {}
}) => {
  const [busy, setBusy] = useState(busyProp);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    setBusy(busyProp);
  }, [busyProp]);

  return (
    <React.Fragment>
      <div
        className={classnames("action-buttons", className)}
        ref={forwardedRef}
      >
        {error ? <FormHelpText theme="error" content={error} /> : null}
        {/* show the secondary buttons */}
        {secondaryVisible && secondary && secondary.length
          ? secondary.map(
            (
              {
                icon,
                label,
                onClick: secondaryOnClick,
                className: secondaryClassName,
              },
              $s,
            ) => {
              return (
                <Button
                  outline
                  className={classnames(
                    secondaryClassName,
                    "action-Buttons-secondary",
                  )}
                  onClick={secondaryOnClick}
                  key={$s}
                >
                  <i className={`fa fa-${icon}`} />
                  &nbsp;{label}
                </Button>
              );
            },
          )
          : null}

        {/* secondary buttons behind dropdown */}
        {!secondaryVisible && secondary && secondary.length ? (
          <DropdownMenu
            items={secondary}
            menuClassname={primary ? "pad-right" : ""}
            toggleClassname="action-buttons-secondary-toggle"
            toggleLabel={""}
          />
        ) : null}

        {primary && (
          <Button
            primary
            disabled={primaryDisabled}
            className="action-buttons-primary"
            onClick={(...onClickArgs: any[]) => {
              setErrorMessage(null);

              const x: any = onClick(...onClickArgs);

              // resolve the "busy" state only when it's not being controlled by prop
              if (busyProp === undefined) {
                setBusy(true);
                if (x instanceof Promise) {
                  // until resolved
                  x.then(() => {
                    setBusy(false);
                  }).catch(getSubmitErrorHandler(setErrorMessage, setBusy));
                } else {
                  // "instant" action
                  window.setTimeout(() => {
                    setBusy(false);
                  }, 150);
                }
              }
            }}
          >
            {busy ? <LoadingIcon /> : primary}
          </Button>
        )}

        {saveAndContinue && (
          <Button
            primary
            disabled={saveAndContinueDisabled}
            className="action-buttons-primary"
            onClick={()=>{return onSaveAndContinue()}}
          >
            {busy ? <LoadingIcon /> : "Save and Continue"}
          </Button>
        )}

        <ConfirmationModal
          title="An error has occurred"
          buttonText="Continue"
          subTitle={errorMessage || ""}
          handleClose={() => setErrorMessage(null)}
          handleConfirm={() => setErrorMessage(null)}
          show={Boolean(errorMessage)}
          hideCancel
        >
          <p>Please contact the administrator for assistance.</p>
        </ConfirmationModal>
      </div>
    </React.Fragment>
  );
};

const ActionButtonsWithRef = React.forwardRef<React.FC, ActionButtonsProps>(
  (props, ref) => <ActionButtonsUI {...props} forwardedRef={ref} />,
);

const ActionButtons = ActionButtonsWithRef;

const ActionButtonsWithScrollFix = ScrollFixHOC(ActionButtons);

export default ActionButtonsWithRef;

export { ActionButtons, ActionButtonsWithScrollFix };
