import React, { useState } from "react";
import ImageWithLightbox from "../../Shared/Image/ImageWithLightbox";

type FindingImageProps = {
  lightbox?: boolean;
  sources: string[];
  imageNumber?: any;
};

export default function FindingImage({ lightbox, sources, imageNumber }: FindingImageProps) {
  const [showLb, setShowLb] = useState(false);

  return (
    <div
      className="finding-image-holder pointer"
      title="Click to enlarge image"
      onClick={
        lightbox
          ? e => {
              e.preventDefault();
              e.stopPropagation();

              setShowLb(true);
            }
          : undefined
      }
    >
      <ImageWithLightbox
        sources={sources}
        showLb={showLb}
        setShowLb={setShowLb}
        className="finding-thumbnail"
      />
      {imageNumber > 1 ? <span className="image-number-text">{`(+${imageNumber - 1} IMGs)`}</span> : null}
    </div>
  );
}

export { FindingImage };
