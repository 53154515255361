import { getRecordReducer, LoadingMessage } from "@sw-sw/lib-ui";
import { find, get, has } from "lodash";
import React, {
  PropsWithChildren,
  useEffect,
  useReducer,
  useState,
} from "react";
import { ToastContainer } from "react-toastify";
import SuccessNotification from "../components/Shared/SuccessNotification/SuccessNotification";
import userApi from "../utils/api/user";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";

type AppContextValue = {
  state: any;
  initialDataLoaded: boolean;
  set(...args: any[]): any;
  get<V = any>(key: string): V;
  has(key: string): boolean;
  reloadInitialData(): void;
  triggerSuccessNotification(): void;

  getNotificationSetting(notificationType: string): {
    default_email_message?: string;
  };
  loadData<D = any>(cb: () => Promise<D>, what?: string): Promise<D>;
  isLoading: boolean;
  loadingResource: string;
  panel: string;
  setPanel: Function
};
const AppContext = React.createContext<AppContextValue>(undefined as any);

export function AppStore(props: PropsWithChildren<{}>) {
  const [state, dispatch] = useReducer(getRecordReducer(), {});
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingResource, setLoadingResource] = useState("resource");
  const getPanelVal = localStorage.getItem("navigationPanel")
  const [panel, setPanel] = useState(getPanelVal === null ? "open" : getPanelVal )
  
  const matomoInstance = createInstance({
    urlBase: "https://sw2.matomo.cloud",
    siteId: 2,
  });

  /**
   * load initial app data
   *
   * @todo catch errors, store in "globalErrors" key, display them
   */
  const set = (key: string, val: any) =>
    dispatch({
      type: "mergeDeep",
      payload: { key, val },
    });

  const loadInitialData = async () => {
    if (userApi.isLoggedIn()) {
      try {
        const appData = await userApi.profile();
        const { appConstants, ...user } = appData;

        dispatch({
          type: "merge",
          payload: {
            user,
            isAdmin: user.roleName === "Admin",
            constants: {
              ...appConstants,
              nonDivisionRoutes: [
                "/divisions",
                "/inspection",
                "/user-manuals",
                "/public/inspection"
              ],
              ccrTemplates: ["Delaware"],
              peTemplates: ["Delaware"],
            },
          },
        });
      } catch (e) {
        userApi.logout();
        setInitialDataLoaded(true);
      }
    } else {
      // nothing to load
      setInitialDataLoaded(true);

      return Promise.resolve();
    }
  };

  const loadData = async (cb: () => Promise<any>, what?: string) => {
    setLoadingResource(what ? what : "");
    setIsLoading(true);

    try {
      const data = await cb();
      setIsLoading(false);
      return data;
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  useEffect(() => {
    const requiredProps = ["user", "isAdmin", "constants"];

    if (
      !initialDataLoaded &&
      requiredProps.every(prop =>
        Object.prototype.hasOwnProperty.call(state, prop),
      )
    ) {
      setInitialDataLoaded(true);
    }
  }, [state, initialDataLoaded]);

  /**
   * Don't continue unless we have _required_ data
   */
  if (!initialDataLoaded) {
    return <LoadingMessage what="system data" />;
  }

  return (
    <AppContext.Provider
      value={{
        panel,
        setPanel,
        state,
        set,
        get: get.bind(null, state),
        has: key =>
          has(state, key) &&
          get(state, key) !== null &&
          get(state, key) !== undefined,

        reloadInitialData: () => loadInitialData(),
        triggerSuccessNotification: () => {
          const toId = window.setTimeout(() => {
            setShowSuccessNotification(false);
          }, 2000);

          setShowSuccessNotification(true);

          return toId;
        },
        getNotificationSetting: notificationType => {
          return find(get.bind(null, state)("constants.notificationSettings"), {
            name: notificationType,
          });
        },
        initialDataLoaded,
        loadData,
        loadingResource,
        isLoading,
      }}
    >
      {" "}
      <MatomoProvider value={matomoInstance}>{props.children}</MatomoProvider>
      <SuccessNotification show={showSuccessNotification} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        pauseOnFocusLoss={false}
        hideProgressBar
      />
    </AppContext.Provider>
  );
}

export default AppContext;
