import React, { useContext, useState } from "react";
import { string, bool, number, shape, func, arrayOf, array } from "prop-types";

import FormModal from "../../Shared/form/modal/FormModal";
import { defaultFindingObservationTemplates } from "../../../utils/clients";
import { FormContext, FormContextProvider } from "@sw-sw/lib-form";
import { FormSchemaFields } from "@sw-sw/lib-form";
import validator from "../../../utils/FormValidator";
import Loading from "../../Shared/ResourceIndex/Loading";
import { useStates } from "../../../hooks/address";
import { useCountries } from "../../../hooks/address";
import { UIControlType } from "@sw-sw/lib-form-control-types";
import { env } from '../../../config'
import NewClientDocTypeFolder from "./NewClientDocTypeFolder";
import clientApi from "../../../utils/api/client";
import ConfirmationModal from "../../Shared/ConfirmationModal/ConfirmationModal";

/**
 * naming convention: variable name "states" refers to -- 
 * 1. states of US (for US region)
 * 2. countries of UK (for UK region)
 */

const deleteCustomDocType = async(docTypeId, setDeleteDocTypeError, setShowDeleteModal, docTypesRefetch) => {
  try{
    let clientId = window.location.pathname.split('/')[4]

    const deleteResponse = await clientApi.deleteCustomClientDocType.index(clientId, docTypeId)

    if(deleteResponse.hasOwnProperty('Error')){
      setDeleteDocTypeError(deleteResponse.Error)
    }
    else{
      setShowDeleteModal(false)
      docTypesRefetch.refetch()
    }
  }
  catch(error){
    console.log(error)
  }
}

/**
 * naming convention: variable name "states" refers to -- 
 * 1. states of US (for US region)
 * 2. countries of UK (for UK region) 
 */
function getSchema(states) {

  const halfWidthStyle = { flex: "1 1 50%", alignSelf: "flex-end" };

  return {
    name: {
      label: "Client Name",
      autoComplete: "organization",
      validation: {
        required: true,
      },
    },
    phone: {
      label: "Phone",
      autoComplete: "tel",
      mask: env.REACT_APP_REGION === "UK" ? "phoneUK" : "phone",
      validation: {
        required: env.REACT_APP_REGION === "UK" ? false : true,
        format: env.REACT_APP_REGION === "UK" ? "phoneUK" : "phone",
      },
    },
    street1: {
      label: "Address",
      placeholder: "Street",
      autoComplete: "address-line1",
      "aria-label": "Street address line 1 of 2",
      validation: {
        required: true,
      },
    },
    street2: {
      placeholder: "Street Line 2",
      "aria-label": "Street address line 2 of 2",
      autoComplete: "address-line2",
    },
    city: {
      placeholder: env.REACT_APP_REGION === "UK" ? "Town/City" : "City",
      "aria-label": "City",
      autoComplete: "address-level2",
      validation: {
        required: true,
      },
    },
    state: {
      controlType: UIControlType.select,
      placeholder: env.REACT_APP_REGION === "UK" ? "Country" : "State",
      "aria-label": "State",
      autoComplete: "address-level1",
      options: states,
      labelKey: "name",
      valueKey: "id",
      style: { flex: "1 1 50%" },
      validation: {
        required: true,
      },
    },
    zip: {
      placeholder: env.REACT_APP_REGION === "UK" ? "Postcode" : "Zip",
      "aria-label": "zip code",
      className: "zip",
      autoComplete: "postal-code",
      maxLength: 5,
      style: { flex: "1 1 50%" },
      validation: {
        required: true,
      },
      inputMode: "numeric",
      parse: validator.parseNumber,
    },
    default_finding_observation: {
      label: "Default Finding Observation",
      controlType: UIControlType.textareaTmpl,
      templates: defaultFindingObservationTemplates,
    },
    disable_images: {
      label: "Disable Images",
      controlType: UIControlType.checkbox,
      placeholder: "Disable Images",
      "aria-label": "Disable Images",
      style: halfWidthStyle,
    },
    precipitation_client_project_flag: {
      label: "Precipitation",
      controlType: UIControlType.toggleButton,
      checked: false,
      toggleSize: 'sm',
      style: halfWidthStyle,
    }
  };
}

function getSchemaNext(docTypes, projectTabs, setShowDeleteModal, setSelectedDocTypeId) {
  const handleDeleteIconClick = async (event, docTypeId) => {
    event.preventDefault()
    setShowDeleteModal(true)
    setSelectedDocTypeId(docTypeId)
  }
  
  return {
    projectTabs: {
      label: "Project Tabs",
      controlType: UIControlType.checkboxGroup,
      options: projectTabs,
      valueKey: "id",
      labelKey: "name",
    },
    docTypes: {
      label: "Document Types",
      controlType: UIControlType.checkboxGroup,
      options: docTypes,
      valueKey: "id",
      labelKey: "name",
      deleteCustomDocType: handleDeleteIconClick,
    }
  };
}

function getInitialData(client) {
  const { address = {} } = client;

  return {
    name: client.name,
    street1: address.street_1,
    street2: address.street_2,
    city: address.city,
    state: env.REACT_APP_REGION === "UK" ? address.countryObj && address.countryObj.id : address.state && address.state.id,
    zip: address.zip,
    phone: address.phone,
    default_finding_observation: client.default_finding_observation,
    disable_images: client.disable_images,
    precipitation_client_project_flag: client.precipitation_client_project_flag,
  };
}

function ClientDetailsFormUI({ states, docTypes, projectTabs, docTypesRefetch, ...props }) {
  const formContext = useContext(FormContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedDocTypeId, setSelectedDocTypeId] = useState(false)
  const [deleteDocTypeError, setDeleteDocTypeError] = useState("")

  return (
    <>
      <FormModal
        className="add-client-form"
        onCancel={props.handleClose}
        onSubmit={props.handleSubmit}
        modalProps={{
          title: props.title,
        }}
        nextBtn={props.nextBtn}
        isNextPage={props.isNextPage}
        handleNext={props.handleNext}
        handleBack={props.handleBack}
      >
        {props.isNextPage ?
          <>
            {/* Add Tab Permission Code here */}
            <FormSchemaFields
              schema={getSchemaNext(docTypes, projectTabs, setShowDeleteModal, setSelectedDocTypeId, docTypesRefetch)}
              onChange={formContext.set}
              formData={formContext.value}
              initialFormData={getInitialData(props.client, docTypes, projectTabs)}
            />

            <NewClientDocTypeFolder {...props} />
          </> :
          <>
            <FormSchemaFields
              schema={getSchema(states)}
              onChange={formContext.set}
              formData={formContext.value}
              initialFormData={getInitialData(props.client)}
            />
          </>
        }
      </FormModal>
      {showDeleteModal && (
        <ConfirmationModal
          title="Delete Folder"
          subTitle={
            deleteDocTypeError ? `${deleteDocTypeError}` :
              `The selected Folder will be deleted and removed from all Projects associated with this client. Are you sure you want to proceed?`
          }
          show={true}
          handleClose={() => {
            setShowDeleteModal(false)
            setDeleteDocTypeError("")
          }}
          handleConfirm={() => deleteCustomDocType(selectedDocTypeId, setDeleteDocTypeError, setShowDeleteModal, docTypesRefetch)}
          buttonText="Delete"
        />
      )}
    </>
  );
}

function ClientDetailsForm(props) {
  const statesQuery = env.REACT_APP_REGION === "UK" ? useCountries() : useStates();

  const defaultChecked = {};

  props.docTypes && props.docTypes.length && props.docTypes.filter(doc => {
    if (doc.hasOwnProperty('is_checked')) {
      return doc.is_checked
    }

    return true
  }).forEach(doc => {
    defaultChecked[doc.id] = true;
  });

  if (!props.show) return null;

  if (statesQuery.isLoading) return <Loading />;

  const filterTabData = () => {
    let projectTabData = {}

    props.projectTabs && props.projectTabs.forEach((tab) => {
      projectTabData[tab.id] = tab.isChecked

    })

    return projectTabData
  }

  return (
    <FormContextProvider
      initialValue={{
        docTypes: defaultChecked,
        projectTabs: filterTabData()
      }}
    >
      <ClientDetailsFormUI states={statesQuery.data} {...props} docTypes={props.docTypes} projectTablis={props.projectTabs} docTypesRefetch={props.docTypesRefetch} />
    </FormContextProvider>
  );
}

const clientShape = shape({
  name: string,
  address: shape({
    phone: string,
    street_1: string,
    street_2: string,
    city: string,
    zip: string,
    state: shape({ id: number }),
  }),
});

ClientDetailsForm.propTypes = {
  // initial data
  client: clientShape,
  // modal props
  handleSubmit: func.isRequired,
  handleClose: func.isRequired,
  show: bool.isRequired,
  title: string.isRequired,
  docTypes: arrayOf(shape({ id: number, name: string })),
  projectTabs: arrayOf(shape({ id: number, name: string })),
  nextBtn: bool,
  isNextPage: bool,
  handleNext: MouseEvent,
  handleBack: MouseEvent,
  newDocTypes: array,
  setNewDocTypes: func
};

ClientDetailsForm.defaultProps = {
  client: {},
};

export default ClientDetailsForm;
