import React, { useState, useContext } from 'react'
import { useMediaQuery } from "react-responsive"
import {Context as DataSourceContext} from '../../DataSourceContext'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown, faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import { MapEditorRouteContext } from "../../../CustomRoutes/MapEditor";



const LegendCardContent = ({ options, feature, handleBackgroundClick, close }) => {
    const dataSourceContext = useContext(DataSourceContext)
    const { mapMobileView, setMapMobileView } = useContext(MapEditorRouteContext)
    const isTabletOrLess = useMediaQuery({ maxWidth: 768 })
    const [ showMethods, setShowMethods ] = useState(true)
    
    const { positionable_type, positionable_source_id } =  feature || {}

    //get all available Pollutants and Control measures in one object
    const filteredDataSource = dataSourceContext.get()
    const regex = new RegExp(/^pollutant$|^control_measure$/)
    const relevantKeys = Object.keys(filteredDataSource).filter(ele => regex.test(ele))
    const allAvailableLegends = relevantKeys.length && filteredDataSource && filteredDataSource[`${relevantKeys[0]}`].map((ele) => ele)

    relevantKeys.length && relevantKeys[1] && allAvailableLegends.push(...filteredDataSource[`${relevantKeys[1]}`].map((ele) => ele))

    const handleDelete = () => {
        options[1].onClick()
    }

    const handleEdit = () => {
        options[0].onClick()
    }

    const legendName = () => {
        const legendObject = allAvailableLegends.filter(ele => ele.id === positionable_source_id)

        return legendObject.length && legendObject[0].name
    }

    if(isTabletOrLess){
        setMapMobileView(true)
    }

    return(
        <>
            <div className='LegendCardContent'>
                <header>
                    <span>{legendName()}</span>
                    <span onClick={(event) => handleBackgroundClick(event, close)}>CLOSE</span>
                </header>

                <div className='LegendCardContent__center'>
                    <p>{`(${positionable_type})`}</p>
                    <p><FontAwesomeIcon className="modal-icon" icon={showMethods ? faAngleUp : faAngleDown} style={{ visibility: (mapMobileView || isTabletOrLess) ? "visible" : "hidden"}}  onClick={() => setShowMethods(!showMethods)}/></p>
                </div>

                <footer style={{ display: !showMethods || !mapMobileView ? "none" : "flex" }}>
                    <p onClick={() => handleDelete()}><FontAwesomeIcon icon={faTrashAlt} color='rgba(250,0,0,0.6)'/> Remove</p>
                    <p onClick={() => handleEdit()}><FontAwesomeIcon icon={faEdit}/> Edit</p>
                </footer>
            </div>
        </>
    )
}



export default LegendCardContent