import {
  FormContext,
  FormContextProvider,
  FormSchemaFields,
} from "@sw-sw/lib-form";
import { TooltipContextProvider } from "@sw-sw/lib-ui";
import { toInteger, transform } from "lodash";
import momentTz from "moment-timezone";
import { array, bool, func, number, shape, string } from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../contexts/AppContext";
import AppDivisionContext from "../../../contexts/AppDivisionContext";
import { useStates } from "../../../hooks/address";
import { useCountries } from "../../../hooks/address";
import { useDivisionClients } from "../../../hooks/client";
import divisionApi from "../../../utils/api/division";
import uploadApi from "../../../utils/api/upload";
import FormModal from "../../Shared/form/modal/FormModal";
import Loading from "../../Shared/ResourceIndex/Loading";
import {
  getSchemaLeft,
  getSchemaRight,
  projectInspectorUserRoles,
} from "./projectFormSchema";
import { env } from '../../../config';
import clientApi from "../../../utils/api/client";
import ProjectContext from "../../../contexts/ProjectContext";
import projectApi from "../../../utils/api/project";

/**
 * UI to edit the next inspection date
 * naming convention: variable name "states" refers to -- 
 * 1. states of US (for US region)
 * 2. countries of UK (for UK region) 
 */
function ProjectFormUI({
  schemaData,
  handleSubmit,
  handleClose,
  disableClient,
  ...props
}) {
  const formContext = useContext(FormContext);
  const appContext = useContext(AppContext);
  const [ clientBasedDocTypes, setClientBasedDocTypes ] = useState([])

  function submit() {
    // clean values
    const { docTypes = {}, routine_interval, ...values } = formContext.value;
    const allClientCheckedDocTypeIds = props.clientCheckedDocTypes.map(ele => ele.id)

    const relevantDoctypes = Object.keys(docTypes)
    .filter((key) => allClientCheckedDocTypeIds.includes(Number(key)))
    .reduce((obj, key) => {
      return Object.assign(obj, {
        [key]: docTypes[key]
      });
    }, {});
    
    return handleSubmit({
      ...values,
      routine_interval: Math.ceil(toInteger(routine_interval)),
      // transform docTypes to array
      docTypes: transform(
        relevantDoctypes,
        (acc, val, key) => {
          if (val) acc.push(toInteger(key));
        },
        [],
      ),
    });
  }

  const formData = formContext.value;
  const formSectionProps = {
    formData,
    onChange: formContext.set,
  };
  const clientData = (formData.client && formData.client.length > 0)? formData.client[0] : false;

  useEffect(async () => {
    if(!formContext.value.clientId){
      setClientBasedDocTypes(schemaData.docTypes)
    }
    else{
      const clientDocType = await clientApi.getClientsDocTypes.index(formContext.value.clientId)
      const filteredClientDocType = clientDocType.filter((ele) => ele.is_checked)

      setClientBasedDocTypes(filteredClientDocType)
    }
  },[formContext.value.clientId])

  return (
    <FormModal
      onSubmit={submit}
      onCancel={handleClose}
      modalProps={{
        hideHeader: true,
        classes: "project-form-modal",
        isSmall: false,
        isWide: true,
        style: {},
      }}
    >
      <section className="project-form">
        <section className="container">
          <FormSchemaFields
            {...formSectionProps}
            schema={getSchemaLeft(
              schemaData.clients,
              schemaData.inspectionTemplates,
              schemaData.states,
              schemaData.users,
              schemaData.tz,
              disableClient,
              formData.inspectionTemplateId,
              formData.inspection_compliance_required,
              appContext.get("constants.optionalComplianceTemplates"),
              appContext.get("constants.cgpTrackingIdTemplates"),
              appContext.get("constants.ccrTemplates"),
              clientData,
              props.getSelectedValue
            )}
          />
        </section>

        <section className="container">
          <FormSchemaFields
            {...formSectionProps}
            schema={getSchemaRight(clientBasedDocTypes, schemaData.docGroups)}
          />
        </section>
        
        {
          (clientData && clientData.precipitation_client_project_flag === false) && 
          <section className="container precipitation-error-msg-wrapper">
              <p>“Precipitation Tracking has been turned off for this client, to access please contact your companies administrator.”</p>
          </section>
        }
        
      </section>
    </FormModal>
  );
}

function ProjectForm({ initialData, ...props }) {
  const appContext = useContext(AppContext);
  const { appDivisionId } = useContext(AppDivisionContext);
  const projectContext = useContext(ProjectContext);

  // form data
  const clientsQuery = useDivisionClients(appDivisionId);
  const statesQuery = env.REACT_APP_REGION === "UK" ? useCountries() : useStates();
  const [docTypes, setDocTypes] = useState([]);
  const [docGroups, setDocGroups] = useState([]);
  const [users, setUsers] = useState([]);

  const [tz] = useState(appContext.get("constants.tz"));
  const [inspectionTemplates, setTemplates] = useState([]);

  // form states
  const [loaded, setLoadedState] = useState(false);
  const [clientId, setClientId] = useState()
  const [checkedDocs, setCheckedDocs] = useState()
  const [clientCheckedDocTypes, setclientCheckedDocTypes] = useState()

  function getSelectedValue(value){
    setClientId(value)
  }

  const projectId = projectContext.project && projectContext.project.id

  useEffect(async() => {
    if(projectId && clientId){
      const projectDocs = await projectApi.getDocs(projectId); //fetching current doctypes addes to Project_doc_types
      const clientDocTypes = await clientApi.getClientsDocTypes.forProjects(clientId) // fetching all checked form Client_doc_types
      const allClientDocTypeIds = clientDocTypes.map(ele => ele.id)
      const clientFilteredDocs = projectDocs.documentTypes.filter((ele) => allClientDocTypeIds.includes(ele.id)) //Project_doc_types - Client_doc_types

      setclientCheckedDocTypes(clientDocTypes)
      setCheckedDocs(clientFilteredDocs);
    }
    else{
      const clientDocTypes = await clientApi.getClientsDocTypes.forProjects(clientId) // fetching all checked form Client_doc_types

      setclientCheckedDocTypes(clientDocTypes)
      setCheckedDocs(clientDocTypes);
    }
  },[clientId])

  useEffect(() => {
    Promise.all([
      clientId ? clientApi.getClientsDocTypes.forProjects(clientId) : uploadApi.docTypes(),
      divisionApi.regulations.index(appDivisionId),
      divisionApi.inspectionTemplates.index(appDivisionId),
      divisionApi.users.index(appDivisionId),
    ])
      .then(([docTypesRes, docGroupsRes, inspTemplRes, usersRes]) => {
        setDocTypes(docTypesRes);
        setDocGroups(docGroupsRes);
        setTemplates(inspTemplRes);
        setUsers(
          usersRes.filter(user =>
            projectInspectorUserRoles.includes(user.roleName),
          ),
        );
        if (
          initialData &&
          initialData.template &&
          inspectionTemplates.filter(({ id }) => id === initialData.template.id)
            .length === 0
        ) {
          setTemplates([initialData.template, ...inspectionTemplates]);
        }

        setLoadedState(true);
      })
      .catch(e => {
        console.log("Error: mounting", e);
      });
  }, []);

  // wait for data to load
  if (!loaded || clientsQuery.isLoading || statesQuery.isLoading) {
    return <Loading />;
  }

  const defaultChecked = {};

  checkedDocs && checkedDocs.forEach(doc => {
    defaultChecked[doc.id] = true;
  });
  const timezone = momentTz.tz.guess(true);

  return (
    <FormContextProvider
      initialValue={{
        ...initialData,
        clientId: clientId ? clientId : initialData.clientId,
        docTypes: defaultChecked,
        timezone: env.REACT_APP_REGION === "UK" ? "GMT" :
          (initialData.timezone && tz.includes(initialData.timezone)
            ? initialData.timezone
            : tz.includes(timezone)
            ? timezone
            : tz[0])
      }}
    >
      <TooltipContextProvider>
        <ProjectFormUI
          {...props}
          schemaData={{
            docTypes,
            states: statesQuery.data,
            clients: clientsQuery.data,
            docGroups,
            inspectionTemplates,
            users,
            tz,
          }}
          getSelectedValue={getSelectedValue}
          clientCheckedDocTypes={clientCheckedDocTypes}
        />
      </TooltipContextProvider>
    </FormContextProvider>
  );
}

const addressObject = {
  street_1: string,
  street_2: string,
  city: string,
  zip: string,
  latitude: string,
  longitude: string,
  state: shape({ id: number, abbreviation: string }),
};

const projectProp = {
  client_id: number,
  address: shape(addressObject),
  start_date: string,
  end_date: string,
  inspection_template_id: number, // ie Inspection Form Type
  findingComplianceRequired: bool,
  permittee: string,
  name: string,
  notes: string,
  routine_interval: number,
  document_group: number, // ie Regulation
};

const schemaDataProp = {
  docTypes: array.isRequired,
  states: array.isRequired,
  clients: array.isRequired,
  docGroups: array.isRequired,
  inspectionTemplates: array.isRequired,
  users: array.isRequired,
  tz: array.isRequired,
};

ProjectFormUI.propTypes = {
  schemaData: shape(schemaDataProp).isRequired,
  handleClose: func.isRequired,
  handleSubmit: func.isRequired,
  disableClient: bool,
};

ProjectForm.propTypes = {
  handleClose: func.isRequired,
  handleSubmit: func.isRequired,
  submitBtnText: string.isRequired,

  initialData: shape(projectProp),
  disableClient: bool,
};

ProjectForm.defaultProps = {
  initialData: {
    inspection_compliance_required: true,
    findingComplianceRequired: true,
  },
};

export default ProjectForm;
