/** Don't save to XHR */

import React, { useContext, useState, useEffect } from "react";
import { MapContextType } from "@sw-sw/common";
import { isNull, forIn, get } from "lodash";

import { Context as InteractionContext } from "../InteractionContext";
import { Context as DataSourceContext } from "../../DataSourceContext";
import { ProjectContext } from "../../../../contexts/ProjectContext";
import { FormContext, FormContextProvider } from "@sw-sw/lib-form";
import { Context as PositionableInstanceDataContext } from "../../Positionable/InstanceDataContext";
import FormModal from "../../../Shared/form/modal/FormModal";
import NoteForm from "../../../Commentable/Note/Form";
import FindingsForm, {
  extractFindingData,
  getInitialData,
} from "../../../Inspections/Findings/FindingsForm";
import LegendItemFormSchema, {
  defaultData,
  extractInitialFormData,
  getSchema,
} from "../../../LegendItems/Form/FormSchema";
import { MapEditorRouteContext } from "../../../CustomRoutes/MapEditor";
import usePositionableSourceModel from "../../hooks/usePositionableSourceModel";
import Loading from "../../../Shared/ResourceIndex/Loading";
import { InspectionContext } from "../../../../contexts/InspectionContext";
import { VersionContextProvider } from "../../../../contexts/VersionContext";
import projectApi from "../../../../utils/api/project";
/**
 * Form to create source models.
 *
 * @see {InstanceDataContext} For handling of unsaved source models
 * @type {{ [positionableType: string]: import('react').FunctionComponent }}
 *
 */
const schema = getSchema();
const forms = {
  findings: ({ inspectionId, initialValue, readOnly, ...props }) => {
    const [init, setInit] = useState(false);
    const inspectionContext = useContext(InspectionContext);
    const projectContext = useContext(ProjectContext);
    const [project, setProject] = useState((projectContext || {}).project)
    const [readOnlyFinding, setReadOnlyFinding] = useState(readOnly);
    
    useEffect(() => {
      if (initialValue && initialValue.date_completed) {
        setReadOnlyFinding(true);
      }
      const initialData = initialValue
        ? extractFindingData(initialValue, inspectionId)
        : getInitialData(inspectionContext.getDefaultFindingDate());

      forIn(initialData, (val, key) => props.onChange(key, val));
      setInit(true);
    }, []);
    useEffect(async() => {
      const url = window.location.href;
      const regex = /projects\/(\d+)\/map/;
      const m = regex.exec(url);

      if (m && m.length > 1) {
        const projectId = m[1];
        const projectDetail = await projectApi.get(projectId);
        
        setProject(projectDetail);
      }
    }, []);

    return init ? (
      <VersionContextProvider>
        {project && <FindingsForm
          inspectionId={inspectionId}
          readOnly={readOnlyFinding}
          {...props}
          templateID={project.inspection_template_id}
          templateName={get(projectContext, 'template')}
        />}
      </VersionContextProvider>
    ) : (
      <Loading />
    );
  },
  control_measure: ({ projectId, projectName, initialValue, ...props }) => (
    <LegendItemFormSchema
      {...props}
      initialValue={
        initialValue
          ? extractInitialFormData(initialValue)
          : {
              ...defaultData(),
              type: "control_measure",

              projectId,
            }
      }
      projectName={projectName}
      canEditType={false}
      schema={schema}
    />
  ),
  pollutant: ({ projectId, projectName, initialValue, ...props }) => (
    <LegendItemFormSchema
      {...props}
      initialValue={
        initialValue
          ? extractInitialFormData(initialValue)
          : {
              ...defaultData(),
              projectId,
              type: "pollutant",
            }
      }
      projectName={projectName}
      canEditType={false}
      schema={schema}
    />
  ),
  commentables: ({ initialValue: sourceModel, ...props }) => (
    <NoteForm
      initialValue={
        sourceModel
          ? Object.assign({}, sourceModel.note, { id: sourceModel.id })
          : { title: new Date().toLocaleDateString() }
      }
      {...props}
    />
  ),
};

// modify form data before passing to the API
const formDataMutators = {
  findings: data => ({
    ...data,
    // default dates
    date_initiated: data.date_initiated ? data.date_initiated : new Date(),
    date_completed: data.date_completed ? data.date_completed : null,
  }),
};

// override onChange handler for specific source model forms
/** @type {{[form: string]: (formContext) => (k: string, v: any) => any}} */
const formOnChangeHandlers = {};

export const positionableTypes = Object.keys(forms);

/**
 * Form and controller to create source model, during map editor
 *
 * @see {InstanceDataContext} and don't save source models to database, at this point!
 */
function SourceModelFormUI({ formProps }) {
  const interactionContext = useContext(InteractionContext);
  const dataSource = useContext(DataSourceContext);
  const formContext = useContext(FormContext);
  const positionableDataContext = useContext(PositionableInstanceDataContext);
  const mapContext = useContext(MapContextType);

  const { positionableType, setDefaultMode, setMode } = interactionContext;

  if (formContext && positionableType && forms[positionableType]) {
    const FormUI = forms[positionableType];

    const interactionDataProps = isNull(interactionContext.modeData)
      ? {}
      : interactionContext.modeData.props;
    const dataTypeArgs = dataSource.getDataTypeArguments(positionableType);

    return (
      <FormModal
        onCancel={() => setDefaultMode()}
        onSubmit={data => {
          const dataMutator =
            formDataMutators[positionableType] || (dat => dat);
          const formData = dataMutator(data);

          return positionableDataContext
            .createSourceModel(formData, positionableType)
            .then(sourceModel => {
              mapContext.reloadLayer(positionableType, sourceModel);
              const { modeData } = interactionContext;
              const sourceModelData = isNull(modeData) ? {} : modeData;

              let {
                nextMode = "create",
                feature = undefined,
                mapEvent = undefined,
              } = sourceModelData;

              setMode(nextMode, {
                sourceModelId: sourceModel.id,
                feature,
                mapEvent,
              });
            });
        }}
        handleClose={() => setDefaultMode()}
        modalProps={{
          title: formProps.initialValue
            ? `Edit ${dataSource.getDataTypeLabel(positionableType, false)}`
            : `Add a ${dataSource.getDataTypeLabel(positionableType, false)}`,
          classes: "positionable-source-model-form",
          ...dataTypeArgs.sourceModelFormModalProps,
        }}
      >
        <FormUI
          {...formProps}
          {...interactionDataProps}
          onChange={
            formOnChangeHandlers[positionableType]
              ? formOnChangeHandlers[positionableType](formContext)
              : formContext.set
          }
          value={formContext.value}
          {...dataTypeArgs.sourceModelFormProps}
        />
      </FormModal>
    );
  }

  return null;
}

export default function SourceModelFormWithContext(props) {
  const mapRouteContext = useContext(MapEditorRouteContext);
  const interactionContext = useContext(InteractionContext);
  const sourceModel = usePositionableSourceModel();

  /** @todo set initial value directly on the Context provider for smoother legend item form */
  return (
    <FormContextProvider>
      <SourceModelFormUI
        formProps={{
          projectId: mapRouteContext.projectId,
          projectName: mapRouteContext.projectName,
          inspectionId: mapRouteContext.selectedInspection
            ? mapRouteContext.selectedInspection.id
            : null,
          projectTemplate: mapRouteContext.projectTemplate,
          initialValue:
            interactionContext.mode === "editSourceModel" && sourceModel
              ? sourceModel
              : undefined,
        }}
      />
    </FormContextProvider>
  );
}