import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { createRef, Fragment, useContext, useState } from "react";
import AppDivisionContext from "../../contexts/AppDivisionContext";
import ClientContext, { ClientStore } from "../../contexts/ClientContext";
import RolesContext from "../../contexts/RolesContext";
import clientApi from "../../utils/api/client";
import projectApi from "../../utils/api/project";
import AddContactModal from "../Contacts/AddContactModal";
import ProjectForm from "../Projects/Forms/ProjectForm";
import { extractInitialData } from "../Projects/Forms/projectFormData";
import ConfirmationModal from "../Shared/ConfirmationModal/ConfirmationModal";
import Popper from "../Shared/Popper/Popper";
import SuccessNotification from "../Shared/SuccessNotification/SuccessNotification";
import ResourceDetailTabs from "./../Shared/ResourceDetail/ResourceDetailTabs";
import ClientDetailsForm from "./Forms/ClientDetailsForm";
import { ClientContactsTab } from "./Tabs/ClientContactsTab";
import ClientProjectsTab from "./Tabs/ClientProjectsTab";
import { env } from '../../config'
import { projectTabsApi } from "../../utils/api/docTypes";
import { useQuery } from "react-query";

const ClientDetail = props => {
  const { getPath } = useContext(AppDivisionContext);
  const [coordinates, setCoordinates] = useState({
    bottom: null,
    right: null,
    left: null,
  });
  const [deleteError, setDeleteError] = useState("");

  // modals
  const [showAddProjectModal, setProjectModalVisibility] = useState(false);
  const [showAddUserModal, setUserModalVisibility] = useState(false);
  const [showSuccessNotification, setSuccessNotificationVisibility] =
    useState(false);
  const [showDeleteModal, setDeleteModalVisibility] = useState(false);
  const [showEditModal, setEditModalVisibility] = useState(false);
  const [showMenu, setMenuVis] = useState(false);

  const permCheck = useContext(RolesContext).userHasPermission;
  const userCanDelete = permCheck("delete", "Client");
  const userCanEdit = permCheck("update", "Client");

  const targetRef = createRef();
  const [ isNextPage, setIsNextPage ] = useState(false)
  const [ newDocTypes, setNewDocTypes ] = useState([])
  
  let clientId = window.location.pathname.split('/')[4]
  const clientdDocTypesQuery = useQuery("ClientDocTypesData", () => clientApi.getClientsDocTypes.index(clientId), {
    refetchOnWindowFocus: false,
  })

  const ClientProjectsTabQuery = useQuery("ClientProjectTab", () => clientApi.getClientsProjectTab.index(clientId), {
    refetchOnWindowFocus: false,
  })

  const getProjectList = useQuery("ProjectList", () => projectTabsApi.get(), {
    refetchOnWindowFocus: false,
  })

  const checkedProjectId = ClientProjectsTabQuery.isFetched && ClientProjectsTabQuery.data.map((tab) => tab.project_tab_id)

  const initialProjectTab = (custom_tab) => {
    if (getProjectList.isFetched && custom_tab) {
      delete getProjectList.data[1]

      return getProjectList.data.map((tab) => {
        if (checkedProjectId && checkedProjectId.includes(tab.id)) {
          return {
            ...tab,
            isChecked: true
          }
        }

        return {
          ...tab,
          isChecked: false
        }
      })
    } else {
      delete getProjectList.data[1]

      return getProjectList.data.map((tab) => {
        return {
          ...tab,
          isChecked: true
        }
      })
    }
  }

  function handleMenuClick() {
    const { right, bottom, left } = targetRef.current.getBoundingClientRect();

    setCoordinates(
      Object.assign(
        { top: bottom },
        right < 100 ? { left } : { right: window.innerWidth - right },
      ),
    );
    setMenuVis(true);
  }

  const getFormattedAddress = address => {
    let stateOrCountry = env.REACT_APP_REGION === "UK" ? (address.countryObj ? address.countryObj.name : null) : (address.state ? address.state.abbreviation : null)

    return (
      <Fragment>
        <h3 style={{ display: "inline-block" }}>{address.street_1}</h3>
        <h3 style={{ display: "inline-block" }}>{address.street_2 ? `, ${address.street_2}` : null}</h3>
        <br />
        <h3 style={{ display: "inline-block" }}>{address.city},</h3>
        <h3 style={{ display: "inline-block" }}>&nbsp;{stateOrCountry}</h3>
        <h3 style={{ display: "inline-block" }}>&nbsp;{address.zip}</h3>
        <h5>{address.phone}</h5>
      </Fragment>
    );
  };

  function handleAddProject(formData) {
    return projectApi.create(formData).then(data => {
      window.setTimeout(() => {
        setSuccessNotificationVisibility(false);
        props.history.push(getPath(`/projects/${data.id}`));
      }, 2000);

      setSuccessNotificationVisibility(true);

      return { errorMsg: "" };
    });
  }

  function handleDelete(client) {
    clientApi
      .destroy(client.id)
      .then(() => {
        setDeleteModalVisibility(false);
        props.history.push(getPath("/clients"));
      })
      .catch(error => {
        setDeleteError(
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "An error has occurred",
        );
      });
  }

  function handleEdit(formData, client, updateClient) { 
    return clientApi.update(client.id, {...formData, newDocTypes: newDocTypes}).then(data => {
      updateClient();
      setEditModalVisibility(false);
    });
  }

  async function handleAddNewDocType(id) {
    return await clientApi.addNewClientDocTypes.index(id, newDocTypes)
  }

  const handleNext = () => {
    setIsNextPage(true)
  }

  const handleBack = () => {
    setIsNextPage(false)
  }

  return (
    <Fragment>
      <ClientStore>
        <ClientContext.Consumer>
          {clientContext => (
            <Fragment>
              <section className="client-detail">
                <header className="pure-g">
                  <div className="detail-content pure-u pure-u-md-1-2 pure-u-1">
                    <h2>{clientContext.client.name}</h2>

                    {clientContext.client.address &&
                      getFormattedAddress(clientContext.client.address)}
                  </div>

                  <div className="detail-actions pure-u pure-u-md-1-2 pure-u-1">
                    {permCheck("create", "Project") && (
                      <button
                        className="primary mobile-hidden"
                        onClick={() => setProjectModalVisibility(true)}
                      >
                        Create New Project
                      </button>
                    )}

                    {userCanEdit && (
                      <button
                        className="outline add-contact-button"
                        onClick={() => setUserModalVisibility(true)}
                      >
                        Add Contact
                      </button>
                    )}

                    {userCanEdit && (
                      <button
                        className="outline edit-client-button"
                        onClick={() => {
                          setNewDocTypes([])
                          setEditModalVisibility(true)
                        }}
                      >
                        Edit
                        <i className="fa fa-pencil pad-left" />
                      </button>
                    )}

                    {userCanDelete && (
                      <button
                        className="outline warn delete-client-button"
                        onClick={() => setDeleteModalVisibility(true)}
                      >
                        Delete
                        <i className="fa fa-trash pad-left" />
                      </button>
                    )}

                    {(userCanDelete || userCanEdit) && (
                      <button
                        className="outline client-kebob"
                        onClick={handleMenuClick}
                        ref={targetRef}
                      >
                        <FontAwesomeIcon icon={faEllipsisV} />
                      </button>
                    )}
                  </div>
                </header>

                <ResourceDetailTabs
                  headers={["Contacts", "Projects"]}
                  panels={[
                    () => <ClientContactsTab />,
                    () => <ClientProjectsTab />,
                  ]}
                />
              </section>

              {/* modals */}
              {showAddProjectModal && (
                <ProjectForm
                  handleClose={() => setProjectModalVisibility(false)}
                  submitBtnText="Create"
                  handleSubmit={handleAddProject}
                  initialData={extractInitialData({
                    client_id: clientContext.client.id,
                  })}
                  disableClient={true}
                />
              )}

              {userCanEdit ? (
                <AddContactModal
                  show={showAddUserModal}
                  hideModal={() => setUserModalVisibility(false)}
                  currentContactIds={clientContext.contactIds}
                  handleAdd={userIds =>
                    clientApi.contacts
                      .create(clientContext.client.id, userIds)
                      .then(clientContext.addContacts)
                  }
                />
              ) : null}

              <ConfirmationModal
                title={`Are you sure you want to delete ${clientContext.client.name}?`}
                subTitle="All associated projects and contacts will also be deleted."
                show={showDeleteModal}
                handleClose={() => setDeleteModalVisibility(false)}
                handleConfirm={() => handleDelete(clientContext.client)}
                buttonText="Delete Client"
                errorMsg={deleteError}
              />

              {isNextPage ?
                <ClientDetailsForm
                handleSubmit={async (formData) => {
                    await handleEdit(
                      formData,
                      clientContext.client,
                      clientContext.updateClient,
                    )

                    await handleAddNewDocType(clientContext.client.id)
                    await clientdDocTypesQuery.refetch()
                    await ClientProjectsTabQuery.refetch()
                    await projectTabsApi.refetch()
                  }
                }
                show={showEditModal}
                handleClose={() => {
                  setEditModalVisibility(false)
                  setIsNextPage(false)
                }}
                title="Edit Client"
                client={clientContext.client}
                docTypes={clientdDocTypesQuery.data}
                nextBtn={true}
                isNextPage={isNextPage}
                handleBack={handleBack}
                newDocTypes={newDocTypes}
                setNewDocTypes={setNewDocTypes}
                projectTabs={initialProjectTab(clientContext.client.custom_tab)}
                docTypesRefetch={clientdDocTypesQuery}
                /> :
                <ClientDetailsForm
                  handleSubmit={formData =>
                    handleEdit(
                      formData,
                      clientContext.client,
                      clientContext.updateClient,
                    )
                  }
                  show={showEditModal}
                  handleClose={() => setEditModalVisibility(false)}
                  title="Edit Client"
                  client={clientContext.client}
                  nextBtn={true}
                  isNextPage={isNextPage}
                  handleNext={handleNext}
                />
              }

              <SuccessNotification show={showSuccessNotification} />

              {showMenu && (userCanDelete || userCanEdit) && (
                <Popper
                  classes="client-menu"
                  coordinates={coordinates}
                  handleClose={() => setMenuVis(false)}
                >
                  <ul>
                    {userCanEdit && (
                      <li
                        className="create-option pointer"
                        onClick={() => setUserModalVisibility(true)}
                      >
                        Add Contact
                      </li>
                    )}
                    {userCanEdit && (
                      <li
                        className="edit-option pointer"
                        onClick={() => {
                          setNewDocTypes([])
                          setEditModalVisibility(true)
                        }}
                      >
                        Edit
                      </li>
                    )}
                    {userCanDelete && (
                      <li
                        className="delete-option pointer"
                        onClick={() => setDeleteModalVisibility(true)}
                      >
                        Delete
                      </li>
                    )}
                  </ul>
                </Popper>
              )}
            </Fragment>
          )}
        </ClientContext.Consumer>
      </ClientStore>
    </Fragment>
  );
};

export default ClientDetail;
