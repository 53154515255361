import React, { useState } from "react";
import {
  format,
  subMonths,
  addMonths,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  addWeeks,
  subWeeks
} from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faAngleDoubleLeft, faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import classNames from "classnames";



const Calendar = ({ dueProjects, getCtaRoute, getCtaText }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());

  const changeMonthHandle = (btnType) => {
    if (btnType === "prev") {
      setCurrentMonth(subMonths(currentMonth, 1));
    }
    if (btnType === "next") {
      setCurrentMonth(addMonths(currentMonth, 1));
    }
  };

  const changeWeekHandle = (btnType) => {
    if (btnType === "prev") {
      setCurrentMonth(subWeeks(currentMonth, 1));
    }
    if (btnType === "next") {
      setCurrentMonth(addWeeks(currentMonth, 1));
    }
  };

  const onDateClickHandle = (day, dayStr) => {
    setSelectedDate(day);
  };

  const handleButtonColors = (dueDate, inspectionStatus) => {
    if(getCtaText(dueDate, inspectionStatus) === "View Project")
      return "calendar-view-show-project-color"
     
    if(getCtaText(dueDate, inspectionStatus) === "Past Due")
      return "calendar-view-past-due-color"
    
    return "calendar-view-start-inspection-color"
  }

  const showInspectionsInCalendar = (date, dueProject) => {
    let convertedCalendarDate = format(date, 'MM-dd-yyyy')
    const filteredProjects = dueProject
    .filter((dueProj) => {
      return dueProj.dueDate.toString() === convertedCalendarDate.toString() 
    })

    if(!filteredProjects[0])
    return null

    return(
      <>
        {filteredProjects.map((filteredProject) => {
          const { siteName, clientName, inspectionStatus, dueDate, projectId } = filteredProject

          return(
          <>
            <div className="calendar-inspection-detail">
              <h6>{siteName}</h6>
              <h6>{clientName}</h6>
              <section className="calendar-inspection-detail__cta">
                <Link to={getCtaRoute( inspectionStatus, dueDate, projectId )}>
                  <span className={classNames(handleButtonColors(dueDate, inspectionStatus))}>{getCtaText(dueDate, inspectionStatus)}</span>
                </Link>
              </section>
            </div>
          </>
          )
        })}
      </>
    )
  }

  const renderHeader = () => {
    const dateFormat = "MMMM yyyy";

    return (
      <div className="header row flex-middle">
        <div className="col col-end flex-gap renderHeader__left">
          <div className="icon" onClick={() => changeMonthHandle("prev")}>
            <FontAwesomeIcon icon={ faAngleDoubleLeft }/>
          </div>
          <div className="icon" onClick={() => changeWeekHandle("prev")}>
            <FontAwesomeIcon icon={ faAngleLeft }/>
          </div>
        </div>
        <div className="col col-center renderHeader__center">
          <span>{format(currentMonth, dateFormat)}</span>
        </div>
        <div className="col col-start flex-gap renderHeader__right">
          <div className="icon" onClick={() => changeWeekHandle("next")}>
            <FontAwesomeIcon icon={ faAngleRight }/>
          </div>
          <div className="icon" onClick={() => changeMonthHandle("next")}>
            <FontAwesomeIcon icon={ faAngleDoubleRight }/>
          </div>
        </div>
      </div>
    );
  };

  const renderDays = () => {
    const dateFormat = "EEE";
    const days = [];

    let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }

    return <div className="days row">{days}</div>;
  };

  const renderCells = (dueProject) => {
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <div
            className={`col col-center cell ${
              isSameDay(day, new Date())
                ? "today"
                : isSameDay(day, selectedDate)
                ? "selected"
                : ""
            }`}
            key={day}
            onClick={() => {
              const dayStr = format(cloneDay, "ccc dd MMMM yy");
              onDateClickHandle(cloneDay, dayStr);
            }}
          >
            <section className="number">{formattedDate}</section>            
            {showInspectionsInCalendar(day, dueProject)}
          </div>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];
    }

    return <div className="body">
      {rows}
      </div>;
  };


  return (
    <div className="calendar__wrapper">
      <div className="calendar">
        {renderHeader()}
        <div className="calendar__body">
          {renderDays()}
          {renderCells(dueProjects)}
        </div>
      </div>
    </div>
  );
};

export default Calendar;

