import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import DashboardContext from "../../../../contexts/DashboardContext";
import Loading from "../../../../components/Shared/ResourceIndex/Loading";
import { AppDivisionContext } from "../../../../contexts/AppDivisionContext";



const ProjectList = () => {
  const [clientIsAscending, setClientIsAscending] = useState(true)
  const [siteIsAscending, setSiteIsAscending] = useState(true)
  const { appDivisionId } = useContext(AppDivisionContext);
  const { displayStatsForMP, isLoadingForMP, setDisplayStatsForMP } = useContext(DashboardContext)

  //SORT
  const sortBySiteName = () => {
    const updatedList = displayStatsForMP.sort((a, b) => {
      let A = a.project.name.toString().toLowerCase()

      let B = b.project.name.toString().toLowerCase()

      if (siteIsAscending === true) {
        setSiteIsAscending(!siteIsAscending)

        return A > B ? -1 : 1 // descending
      }

      else {
        setSiteIsAscending(!siteIsAscending)

        return A > B ? 1 : -1 //ascending
      }
    })

    setDisplayStatsForMP(updatedList)
  }
  
  const sortByClientName = () => {
    const updatedList = displayStatsForMP.sort((a, b) => {
      let A = a.project.client.name.toString().toLowerCase()

      let B = b.project.client.name.toString().toLowerCase()

      if (clientIsAscending === true) {
        setClientIsAscending(!clientIsAscending)

        return A > B ? -1 : 1 // descending
      }

      else {
        setClientIsAscending(!clientIsAscending)

        return A > B ? 1 : -1 //ascending
      }
    })

    setDisplayStatsForMP(updatedList)
  }
  
  return (
    <div className="projects-list">
      <div className="projects-list__content">
        <div className="projects-list__table-head">
          <h3 onClick={sortBySiteName}>Site Name <span><FontAwesomeIcon icon={faSort} /></span></h3>
          <h3 onClick={sortByClientName}>Client Name <span><FontAwesomeIcon icon={faSort} /></span></h3>
          <h3>Action</h3>
        </div>

        {!isLoadingForMP ?
          (displayStatsForMP && displayStatsForMP.map((ele, index) => {

            return (
              <div key={index} className="projects-list__table-body">
                <li>{ele.project.name}</li>
                <li>{ele.project.client.name}</li>

                <li>
                  <Link to={`/divisions/${appDivisionId}/projects/${ele.project_id}/inspections`}>
                    <span>View Project</span>
                  </Link>
                </li>
              </div>
            )
          })) :
          (<Loading what="data" />)
        }
      </div>
    </div>
  );
};

export default ProjectList;

