import { InspectionForm } from "@sw-sw/common";
import { formControls } from "@sw-sw/lib-form";
import {
  QuestionControlType,
  UIControlType,
} from "@sw-sw/lib-form-control-types";
import { QuestionFeature } from "@sw-sw/lib-inspection-templates";
import {
  CommentDeleteModal,
  CommentEditModal,
} from "@sw-sw/ui-inspection-templates";
import classnames from "classnames";
import {
  asField,
  Checkbox,
  Form,
  Option,
  Radio,
  RadioGroup,
  Text,
  TextArea,
} from "informed";
import { isEmpty, mapValues, pick, toString } from "lodash";
import moment from "moment";
import PropTypes, {
  array,
  bool,
  func,
  object,
  shape,
  string,
} from "prop-types";
import React, { Component, createRef, Fragment, useEffect } from "react";
import DatePicker from "react-datepicker";
import { QuestionCommentProvider } from "../../../contexts/QuestionCommentContext";
import CustomSelect from "../../FormInput/CustomSelect";
import { AnyTemplateLayout } from "./AnyTemplateLayout";
import { isCdotRoutineInspectionType } from "../../../utils/cdot";

const CustomTagSelect = formControls.customTagSelect;

/**
 * react select as informed field
 */
const CustomTagSelectUI = asField(({ fieldState, fieldApi, ...props }) => {
  const { value } = fieldState;
  const { setValue, setTouched } = fieldApi;
  const { children, value: valueProp, onChange, onBlur, ...rest } = props;

  useEffect(() => {
    // initial value
    setValue(valueProp);
  }, []);

  return (
    <CustomTagSelect
      {...rest}
      value={value || []}
      onChange={opts => {
        const nextValue = opts.map(o => pick(o, "value")); // assume we're using the default valueKey

        setValue(nextValue);
        if (onChange) onChange(nextValue);
      }}
      onBlur={e => {
        setTouched(true);
        if (onBlur) onBlur(e);
      }}
      getIndicatorStyles={() => ({ display: "flex" })}
    >
      {children}
    </CustomTagSelect>
  );
});

/**
 * @deprecated use {DynamicTemplateQuestions}
 */
export class LegacyTemplateQuestions extends Component {
  static propTypes = {
    handleSubmit: func.isRequired,
    onCommentSave: func.isRequired,
    inspection: object.isRequired,
    template: shape({
      question_groups: array.isRequired,
    }),
    questionTypes: array.isRequired,
    isReadOnly: bool.isRequired,
    permCheck: func.isRequired,
    /**
     * Name of the current user. Only used when editing to provide a default value for questions with {QuestionFeature.defaultToCurrentUser}
     *
     * Only implemented with text input
     */
    currentUserName: string,
    findings: PropTypes.arrayOf(
      shape({
        id: PropTypes.number,
        number: PropTypes.number,
        location: PropTypes.string,
      }),
    ),
  };

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      commentQuestionID: null,
      commentText: "",
      showCommentDeleteButton: false,
      showCommentDeleteModal: false,
      modalError: "",
      isDirty: false, // informed's `dirty` is compromised onMount and doesn't track user events
      busy: false, // whether the form is busy saving
      error: null,
    };

    this.formApi = null;
    this.formRef = createRef();
    this.actionButtonsRef = createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.inspection !== this.props.inspection) {
      const { inspection } = this.props;

      if (inspection && !isEmpty(inspection)) {
        this.formApi.setValues(inspection);
      }
    }
  }

  componentWillUnmount() {
    const isDisabled = this.checkReadOnlyMode();

    if (!isDisabled && this.state.isDirty) {
      // clone to prevent data mutation from formApi (Informed)
      const formData = Object.assign(
        {},
        mapValues(this.formApi.getState().values, val =>
          // serialize arrays
          Array.isArray(val) ? JSON.stringify(val) : val,
        ),
      );

      this.props.handleSubmit(formData, false);
    }
  }

  // SETTERS
  setFormApi = formApi => {
    this.formApi = formApi;
  };

  setDate = (date, field) => {
    // if(field === 46){
      this.formApi.setValue(field, moment(date).format("YYYY-MM-DDTHH:mm:ss"));
    // }else{
    //   this.formApi.setValue(field, date);
    // }
  };

  formatNumber = value => {
    // only numbers and decimals
    return value ? value.trim().replace(/[^0-9.]/gi, "") : value;
  };
  // formatting won't work unless there's a parsing method as well
  parseNumber = value => value;

  // GETTERS
  getAnswer = ({ isArrayType, type, answer, index }) => {
    const jsonQuestionTypes = [
      QuestionControlType.findingArray,
      QuestionControlType.checkbox,
    ];

    // only parse if input has an answer
    if (answer && answer.length > 0) {
      if (isArrayType) {
        return JSON.parse(answer)[index];
      } else if (jsonQuestionTypes.some(qt => qt === type)) {
        return JSON.parse(answer);
      }
    }

    return answer;
  };

  // EVENT HANDLERS
  showCommentModal = (event, question) => {
    event.preventDefault();

    console.log(question.comment);
    this.setState({
      showModal: true,
      commentQuestionID: question.id,
      commentText: question.comment,
      showCommentDeleteButton: question.comment.length > 0,
    });
  };

  hideCommentDeleteModal = () => {
    this.setState({
      showCommentDeleteModal: false,
    });
  };

  hideCommentModal = () => {
    this.setState({
      showModal: false,
      commentQuestionID: null,
      commentText: "",
      showCommentDeleteButton: false,
      modalError: "",
    });
  };

  handleCommentSubmit = value => {
    this.props.onCommentSave({
      questionId: this.state.commentQuestionID,
      inspectionId: this.props.inspection.id,
      value,
    });

    this.hideCommentModal();
  };

  handleCommentDelete = () => {
    this.props.onCommentDelete({
      questionId: this.state.commentQuestionID,
      inspectionId: this.props.inspection.id,
    });

    this.hideConfirmationModal();
    this.hideCommentModal();
  };

  showConfirmationModal = e => {
    this.setState({ showCommentDeleteModal: true });
  };

  hideConfirmationModal = e => {
    this.setState({ showCommentDeleteModal: false });
  };

  checkReadOnlyMode = () =>
    this.props.isReadOnly || !this.props.permCheck("update", "Inspection");

  // RENDER METHODS
  renderDescriptionText = text => {
    return (
      <p
        className="description"
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    );
  };

  renderInput = ({
    question,
    type,
    inputProps,
    index,
    isTableElement,
    isArrayType,
  }) => {
    // extra support for index-type questions
    const questionID = isArrayType ? question.id + "-" + index : question.id;
    const questionField = isArrayType
      ? `${question.id}[${index}]`
      : question.id;

    var answer = this.getAnswer({
      isArrayType,
      type,
      answer: question.answer,
      index,
    });

    // table elements have numerous label sources
    const ariaProps = isTableElement
      ? { "aria-labelledby": `header-${index} ${question.id}-0` }
      : { id: questionID };
    // deconstruct props for specific question types
    const { popperClassName, popperPlacement, rows } = inputProps;
    const datePickerProps = { popperClassName, popperPlacement };
    const textAreaProps = { rows };

    const isDisabled = this.checkReadOnlyMode();
    // the form can only be `dirty` once, so we only need this to run once
    const changeHandler = this.state.isDirty
      ? () => {}
      : () => this.setState({ isDirty: true });

    switch (type) {
      case QuestionControlType.selectOne:
      case QuestionControlType.selectAny:
        return (
          <CustomSelect
            field={questionField}
            initialValue={answer}
            multiple={type === "select many"}
            disabled={isDisabled}
            {...ariaProps}
            className="form-control"
            onChange={changeHandler}
          >
            <Option value="" disabled>
              Select...
            </Option>
            {question.options.map(option => (
              <Option value={option.id} key={option.id}>
                {option.name}
              </Option>
            ))}
          </CustomSelect>
        );

      case QuestionControlType.time:
        let timeString = this.formApi.getValue(questionField);

        if (!timeString && answer.length === 5) {
          timeString = answer;
        } else if (!timeString && moment(answer).isValid()) {
          timeString = moment(answer).format("HH:mm");
        }

        const getDateValue = value => {
          if (value) {
            return moment()
              .set({
                hour: value.substring(0, 2),
                minute: value.substring(3, 5),
              })
              .toDate();
          }
        };

        const onChange = time => {
          this.formApi.setValue(questionField, moment(time).format("HH:mm"));
          this.setState({ isDirty: true });
        };

        return (
          <Fragment>
            <DatePicker
              onChange={onChange}
              disabled={isDisabled}
              showTimeSelect={true}
              showTimeSelectOnly={true}
              timeIntervals="15"
              dateFormat="h:mm a"
              selected={getDateValue(timeString)}
              {...ariaProps}
              {...datePickerProps}
              className="form-control"
            />
            <Text
              field={questionField}
              type="hidden"
              initialValue={timeString}
              hidden
            />
          </Fragment>
        );

      case UIControlType.date:
      case QuestionControlType.datetime:
        const isDateTime = type === "datetime";
        const timeProps =
          type === "date"
            ? {
                maxDate: question.features.includes(
                  QuestionFeature.isInspectionDate,
                )
                  ? new Date()
                  : undefined,
              }
            : {
                showTimeSelect: isDateTime,
                timeIntervals: 15,
                dateFormat: isDateTime ? "MMMM d, yyyy h:mm aa" : undefined,
              };

        const dateValue = this.formApi.getValue(questionField);

        return (
          <Fragment>
            <DatePicker
              onChange={date => {
                this.setDate(date, questionField);
                this.setState({ isDirty: true });
              }}
              disabled={isDisabled}
              selected={
                dateValue && moment(dateValue).isValid()
                  ? moment(dateValue, "YYYY-MM-DDTHH:mm:ss").toDate()
                  : ""
              }
              {...ariaProps}
              {...timeProps}
              {...datePickerProps}
              className="form-control"
            />
            <Text
              field={questionField}
              type="hidden"
              hidden
              initialValue={answer}
            />
          </Fragment>
        );

      case QuestionControlType.booleanWithNa:
      case QuestionControlType.boolean:
      case QuestionControlType.radioGroup:
        const optionsArray =
          type === "boolean" ? ["Yes", "No"] : ["Yes", "No", "N/A"];
        const options = question.options.length
          ? question.options
          : optionsArray.map((name, i) => ({
              id: i + 1,
              name,
              child_questions: [],
            }));

        return (
          <RadioGroup
            field={questionField}
            initialValue={answer}
            onChange={changeHandler}
          >
            {options.map(option => {
              const optionID = questionID + "-" + option.id;

              return (
                <Fragment key={optionID}>
                  <label className="radio-label" id={optionID}>
                    <div
                      className={classnames("control-wrapper", {
                        disabled: isDisabled,
                      })}
                    >
                      <Radio
                        disabled={isDisabled}
                        value={toString(option.id)}
                        field={questionField}
                        aria-labelledby={questionID + " " + optionID}
                        className="form-control"
                      />
                      <span>{option.name}</span>
                    </div>

                    {Boolean(option.description) &&
                      this.renderDescriptionText(option.description)}
                  </label>

                  {option.child_questions.length && this.formRef.current ? (
                    <div className="child-questions-wrapper pure-g">
                      {option.child_questions.map(childQuest =>
                        this.formRef.current.renderQuestion({
                          question: childQuest,
                          isTableElement: false,
                        }),
                      )}
                    </div>
                  ) : null}
                </Fragment>
              );
            })}
          </RadioGroup>
        );

      case UIControlType.checkbox:
        return (
          <Fragment>
            {question.options.map((option, i) => {
              const optionField = `${questionField}[${i}]`;
              const optionID = questionID + "-" + option.id;

              return (
                <Fragment key={optionID}>
                  <label className="checkbox-label" id={optionID}>
                    <div
                      className={classnames("control-wrapper", {
                        disabled: isDisabled,
                      })}
                    >
                      <Checkbox
                        initialValue={
                          this.props.template.name === "CDOT" && i === 0
                            ? isCdotRoutineInspectionType(answer)
                            : JSON.parse(answer)[i]
                        }
                        field={optionField}
                        aria-labelledby={questionID + " " + optionID}
                        disabled={isDisabled}
                        className="form-control"
                        onChange={changeHandler}
                      />
                      <span>{option.name}</span>
                    </div>

                    {Boolean(option.description) &&
                      this.renderDescriptionText(option.description)}
                  </label>

                  {option.child_questions.length && this.formRef.current ? (
                    <div className="child-questions-wrapper pure-g">
                      {option.child_questions.map(childQuest =>
                        this.formRef.current.renderQuestion({
                          question: childQuest,
                          isTableElement: false,
                        }),
                      )}
                    </div>
                  ) : null}
                </Fragment>
              );
            })}
          </Fragment>
        );

      case QuestionControlType.singleCheckbox:
        return (
          <label
            className={classnames("checkbox-label checkbox-label-single", {
              disabled: isDisabled,
            })}
          >
            <Checkbox
              disabled={isDisabled}
              field={questionField}
              initialValue={answer === "true" || answer === true}
              {...ariaProps}
              className="form-control"
              onChange={changeHandler}
            />
            <span>{question.input_label}</span>

            {Boolean(question.description) &&
              this.renderDescriptionText(question.description)}
          </label>
        );

      case QuestionControlType.descriptiveText:
        return (
          <Fragment>
            {question.input_label
              .split("\n")
              .map(
                (text, i) =>
                  text.length > 0 && (
                    <p
                      key={questionID + "-text-" + i}
                      className="description"
                      dangerouslySetInnerHTML={{ __html: text }}
                    />
                  ),
              )}
            {Boolean(question.description) &&
              this.renderDescriptionText(question.description)}
          </Fragment>
        );

      case QuestionControlType.textArea:
        return (
          <TextArea
            disabled={isDisabled}
            field={questionField}
            initialValue={answer}
            {...textAreaProps}
            {...ariaProps}
            className="form-control"
            onChange={changeHandler}
          />
        );

      case QuestionControlType.findingArray:
        if (!Array.isArray(answer)) {
          answer = [];
        }

        return (
          <CustomTagSelectUI
            isMulti
            openOnFocus
            noOptionMessage="No findings available"
            placeholder="Select findings"
            field={questionField}
            value={answer}
            initialValue={answer}
            disabled={isDisabled}
            {...ariaProps}
            className="form-control"
            onChange={changeHandler}
            options={this.props.findings}
          />
        );

      case UIControlType.text:
      case QuestionControlType.number:
      default:
        const numberProps =
          type === "number"
            ? {
                format: this.formatNumber,
                parse: this.parseNumber,
              }
            : {};

        return (
          <Text
            type="text"
            disabled={isDisabled}
            field={questionField}
            initialValue={
              !answer &&
              question.features.includes(QuestionFeature.defaultToCurrentUser)
                ? this.props.currentUserName || ""
                : answer
            }
            {...ariaProps}
            {...numberProps}
            className="form-control"
            onChange={changeHandler}
          />
        );
    }
  };

  render() {
    const { handleSubmit, template, questionTypes, isReadOnly, currentUserName, inspTempAnswerSaveResponse } = this.props;
    const readOnly = this.checkReadOnlyMode();
    let saveBtnState = 'save';

    const handleFormSubmit = (formData) => {
      this.setState({ busy: true, error: null }, async () => {
        try {
          await handleSubmit(formData,saveBtnState);
          this.setState({ busy: false, isDirty: false });
        } catch (err) {
          console.trace("LegacyTemplateQuestion Form onSubmit", err);
          this.setState({
            busy: false,
            error: err.message || "Unknown Error",
            isDirty: false,
          });
        }
      });
    }

    const onSaveAndContinueClick = () => {
      saveBtnState = 'saveAndContinue';
      this.formApi.submitForm();
    }
    
    let erroredQuestionIDs = [];
    if(inspTempAnswerSaveResponse && inspTempAnswerSaveResponse.status !== "complete" && inspTempAnswerSaveResponse.unansweredAndRequired  && inspTempAnswerSaveResponse.unansweredAndRequired.length > 0){
      erroredQuestionIDs = inspTempAnswerSaveResponse.unansweredAndRequired.map(e=>e.id);
    }

    if(currentUserName === "Public") {
      return (
        <AnyTemplateLayout
          templateName={template.name}
          onSave={() => this.formApi.submitForm()}
          busy={this.state.busy}
          error={this.state.error}
          isReadOnly={isReadOnly}
          isPublic={true}
          onSaveAndContinue={onSaveAndContinueClick}
        >
          <Form
            onSubmit={formData => {
              handleFormSubmit(formData)
            }}
            getApi={this.setFormApi}
            render={() => (
              <InspectionForm
                questionTypes={questionTypes}
                categories={template.question_groups}
                renderInput={this.renderInput.bind(this)}
                handleIconClick={this.showCommentModal}
                ref={this.formRef}
                isReadOnly={isReadOnly}
                erroredQuestionIDs={erroredQuestionIDs}
              />
            )}
          />
          {this.state.showModal ? (
            <CommentEditModal
              readOnly={isReadOnly}
              onClose={this.hideCommentModal}
              onSubmit={this.handleCommentSubmit}
              initialValue={this.state.commentText}
              onDelete={
                this.state.commentText ? this.showConfirmationModal : () => {}
              }
            />
          ) : null}
        </AnyTemplateLayout>
      )
    }

    if(currentUserName === "Public") {
      return (
        <AnyTemplateLayout
          templateName={template.name}
          onSave={() => this.formApi.submitForm()}
          busy={this.state.busy}
          error={this.state.error}
          isReadOnly={isReadOnly}
          isPublic={true}
        >
          <Form
            onSubmit={formData => {
              this.setState({ busy: true, error: null }, async () => {
                try {
                  await handleSubmit(formData);
                  this.setState({ busy: false, isDirty: false });
                } catch (err) {
                  console.trace("LegacyTemplateQuestion Form onSubmit", err);
                  this.setState({
                    busy: false,
                    error: err.message || "Unknown Error",
                    isDirty: false,
                  });
                }
              });
            }}
            getApi={this.setFormApi}
            render={() => (
              <InspectionForm
                questionTypes={questionTypes}
                categories={template.question_groups}
                renderInput={this.renderInput.bind(this)}
                handleIconClick={this.showCommentModal}
                ref={this.formRef}
                isReadOnly={isReadOnly}
              />
            )}
          />
          {this.state.showModal ? (
            <CommentEditModal
              readOnly={isReadOnly}
              onClose={this.hideCommentModal}
              onSubmit={this.handleCommentSubmit}
              initialValue={this.state.commentText}
              onDelete={
                this.state.commentText ? this.showConfirmationModal : () => {}
              }
            />
          ) : null}
        </AnyTemplateLayout>
      )
    }

    return (
      <QuestionCommentProvider>
        <AnyTemplateLayout
          templateName={template.name}
          onSave={() => this.formApi.submitForm()}
          busy={this.state.busy}
          error={this.state.error}
          isReadOnly={readOnly}
          onSaveAndContinue={onSaveAndContinueClick}
        >
          <Form
            onSubmit={(formData) => {
              handleFormSubmit(formData)
            }}
            getApi={this.setFormApi}
            render={() => (
              <InspectionForm
                questionTypes={questionTypes}
                categories={template.question_groups}
                renderInput={this.renderInput.bind(this)}
                handleIconClick={this.showCommentModal}
                ref={this.formRef}
                isReadOnly={isReadOnly}
                erroredQuestionIDs={erroredQuestionIDs}
              />
            )}
          />
          {this.state.showModal ? (
            <CommentEditModal
              readOnly={readOnly}
              onClose={this.hideCommentModal}
              onSubmit={this.handleCommentSubmit}
              initialValue={this.state.commentText}
              onDelete={
                this.state.commentText ? this.showConfirmationModal : () => {}
              }
              isFeatureType={this.state.commentQuestionID === 7 ? true : false}
            />
          ) : null}
          {this.state.showCommentDeleteModal ? (
            <CommentDeleteModal
              /** @todo this.state.comment question id? */
              onSubmit={this.handleCommentDelete}
              onClose={this.hideCommentDeleteModal}
            />
          ) : null}
        </AnyTemplateLayout>
      </QuestionCommentProvider>
    );
  }
}
