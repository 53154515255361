import { isEmpty } from 'lodash';
import React, { useContext, useState } from 'react';
import { useRouteMatch } from 'react-router';
import InspectionContext from '../../../contexts/InspectionContext';
import { ProjectContext } from '../../../contexts/ProjectContext';
import { withProjectContext } from '../../../hoc/withProjectContext';
import { useClient } from '../../../hooks/client';
import { getInspectionTypeLabel } from '../../../utils';
import NavTabs from '../../Shared/NavTabs';
import Loading from '../../Shared/ResourceIndex/Loading';
import InspectionCertification from '../Certification/InspectionCertification';
import InspectionQuestions from '../Details/InspectionQuestions';
import Findings from '../Findings/Findings';
import InspectionHeader from '../Header/InspectionHeader';
import InspectionMap from '../Map/InspectionMap';
import InspectionPrint from './InspectionPrint';
import SiteMapPrint from './SiteMapPrint';

function InspectionDetail() {
  const projectContext = useContext(ProjectContext)
  const inspectionContext = useContext(InspectionContext)
  const match = useRouteMatch<{ id: string }>();
  const [modal, setModal] = useState<null | 'print' | 'siteMapPrint'>(null);
  const isPublic = match.path.startsWith("/public")

  const clientQuery = projectContext.project ? useClient(projectContext.project.client_id) : null;

  if (
    !projectContext.project ||
    !inspectionContext.inspection ||
    isEmpty(inspectionContext.inspection)
  ) {
    return (
      <section className='inspection-detail'>
        <Loading what='inspection details' showLoadingText />
      </section>
    );
  }

  return (
    <section className='inspection-detail'>
      <InspectionHeader
        title={getInspectionTypeLabel(inspectionContext.inspection)}
        clientName={clientQuery && clientQuery.data ? clientQuery.data.name : null}
        customTab={clientQuery && clientQuery.data ? clientQuery.data.custom_tab : null}
        projectName={projectContext.projectName}
        enablePrint
        handlePrint={() => setModal('print')}
        handleSiteMapPrint={() => setModal('siteMapPrint')}
        inspectionNumber={inspectionContext.inspection.number}
        inspectionId={inspectionContext.inspection.id}
      />

      <NavTabs
        tabs={[
          {
            to: '/questionnaire',
            label: 'Inspection Questions',
            Component: InspectionQuestions,
          },
          {
            label: 'Findings',
            to: '/findings',
            showTab: (func) => func('read', 'Findings') || isPublic,
            Component: Findings,
          },
          {
            label: 'Map',
            to: '/map',
            Component: InspectionMap,
          },
          {
            label: 'Certification',
            to: '/certification',
            showTab: (func) =>
              func('all', 'Certify') || func('all', 'Sign Off') || isPublic,
            Component: InspectionCertification,
          },
        ]}
        url={match.url}
      />
      {/* load modals! */}
      {modal === 'print' ? (
        <InspectionPrint onClose={() => setModal(null)} />
      ) : null}
      {modal === 'siteMapPrint' ? (
        <SiteMapPrint onClose={() => setModal(null)} />
      ) : null}
    </section>
  );
}

export default withProjectContext(InspectionDetail);
