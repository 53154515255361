import React, { useContext, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import RolesContext from '../../contexts/RolesContext';
import AppDivisionContext from '../../contexts/AppDivisionContext';
import AppContext from '../../contexts/AppContext';
import Logo from '../../images/SW-Logo-White.svg';
import { shape, string, bool } from 'prop-types';
import {
  faStepBackward,
  faStepForward,
} from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive';

const Navigation = (props) => {
  const navItems = useContext(RolesContext).getNavItems();
  const appDivisionContext = useContext(AppDivisionContext);
  const appContext = useContext(AppContext);
  const { panel, setPanel } = appContext;
  const isTabletOrLess = useMediaQuery({ maxWidth: 992 });
  const setDimension = () => ({ width: window.innerWidth });
  const [isWidth, setIsWidth] = useState(isTabletOrLess ? true : false);
  const getDivisionPath = () => {
    const regex = /\/divisions\/(\d+)\/([a-z,-]*)/;

    return props.match.url.match(regex);
  };

  const isDivisionRoute = (path) => {
    const { nonDivisionRoutes } = appContext.get('constants');

    return nonDivisionRoutes && nonDivisionRoutes.includes(path) ? false : true;
  };

  const sidebarToggle = () => {
    if (panel === "open") {
      setPanel("close")
      localStorage.setItem('navigationPanel', "close");
    }
    else {
      setPanel("open")
      localStorage.setItem('navigationPanel', "open");
    }
  };

  const newDimension = () => {
    if (window.innerWidth < 992) {
      setIsWidth(true);
      setDimension({ width: window.innerWidth });
    } else {
      setIsWidth(false);
    }
  };

  window.addEventListener('resize', newDimension);

  return (
    <nav aria-label='primary side-navigation'>
      {isWidth ? null : (
        <button onClick={sidebarToggle} className='sidebarToggleButton'>
          {panel === 'open' ? (
            <FontAwesomeIcon
              icon={faStepBackward}
              className='sidebarToggleIcon'
            />
          ) : (
            <FontAwesomeIcon
              icon={faStepForward}
              className='sidebarToggleIcon'
            />
          )}
        </button>
      )}

      {props.isHamburger ? (
        <div />
      ) : (
        <Link to='/dashboard' className='home-link'>
          <img src={Logo} className='logo pure-img' alt='logo' />
        </Link>
      )}

      <ul className={`side-nav ${panel === 'open' ? '' : 'sideNaveShift'}`}>
        {navItems
          .filter((navItem) => {
            if (
              !appDivisionContext.appDivisionId &&
              isDivisionRoute(navItem.route)
            ) {
              return false;
            }

            return true;
          })
          .map((navItem) => (
            <li
              key={navItem.name}
              title={panel === 'open' ? '' : navItem.name}
            >
              <NavLink
                activeClassName='selected'
                to={
                  isDivisionRoute(navItem.route)
                    ? appDivisionContext.getPath(navItem.route)
                    : navItem.route
                }
                isActive={
                  navItem.isActivePath
                    ? () => {
                      const activePath = getDivisionPath();

                      return (
                        navItem.isActivePath.indexOf(
                          activePath
                            ? activePath[2]
                            : props.match.path.split('/')[1],
                        ) > -1
                      );
                    }
                    : null
                }
              >
                <FontAwesomeIcon icon={navItem.icon} />

                {panel === 'open' || isWidth ? <span>{navItem.name}</span> : null}
              </NavLink>
            </li>
          ))}
      </ul>
    </nav>
  );
};

Navigation.propTypes = {
  match: shape({
    path: string.isRequired,
  }).isRequired,
  isHamburger: bool,
};

Navigation.defaultProps = {
  isHamburger: false,
};

export default Navigation;
