import { Modal } from "@sw-sw/lib-ui";
import { capitalize, findIndex } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import AppDivisionContext from "../../contexts/AppDivisionContext";
import RolesContext from "../../contexts/RolesContext";
import { getDivisionResourceName } from "../../hooks/divisionResource";
import userApi from "../../utils/api/user";
import ConfirmationModal from "../Shared/ConfirmationModal/ConfirmationModal";
import ResourceIndex from "../Shared/ResourceIndex/ResourceIndex";
import AddUserModal from "./AddUser/AddUserModal";
import UserDetail from "./Detail";
import { Redirect } from "react-router-dom";
import DashboardContext from "../../contexts/DashboardContext";
// import EditUserModal from "./EditUser/EditUserModal"

import UserStatus, { USER_STATUSES } from "./Index/UserStatus";

/** title and subtitle to toggle activation on a user */
const getActivationModalContent = (status, name) => {
  const action = status === USER_STATUSES.active ? "de-activate" : "activate";

  return {
    title: `${capitalize(action)} User`,
    subTitle: [`Are you sure you want to ${action} ${name}?`],
  };
};

const resourceName = getDivisionResourceName("users"); // for api data
const pageName = "Admin"; // functional area for permissions

const Users = () => {
  const appDivisionContext = useContext(AppDivisionContext);
  const queryClient = useQueryClient();
  const permCheck = useContext(RolesContext).userHasPermission;
  const canCreate = permCheck("create", pageName);
  const {getTeamManagementData} = useContext(DashboardContext)
  /** keep track of invitation modal */
  const [showInvite, setShowInvite] = useState(false);
  const [showActivation, setShowActivation] = useState(false);

  /** keep track selected user */
  const [selectedId, setSelectedId] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState();

  const [roleName, setRoleName] = useState()
  /**
   * keep track of user ids that are "busy"
   */
  const [showSpinner, setShowSpinner] = useState([]);
  const hideSpinner = useCallback((id, idList) => {
    // find index inviteId in showSpinner
    const idIndex = findIndex(idList, id);

    setShowSpinner([...idList.slice(0, idIndex), ...idList.slice(idIndex + 1)]);
  }, []);

  const selectUser = useCallback(user => {
    setSelectedId(user.id);
    setSelectedName(user.name);
    setSelectedStatus(user.status);
  });

  const deselect = useCallback(() => {
    setSelectedId(null);
    setSelectedName(null);
    setSelectedStatus(null);
  });

  useEffect(() => {
    userApi.profile().then((res) => {
      setRoleName(res.roleName)
    })
  })

  return (
    <div className="users-index">
      <ResourceIndex
        resourceName={resourceName}
        resourceQueryProps={{ divisionId: appDivisionContext.appDivisionId }}
        resourceTableHeaders={[
          ["First Name", "first_name"],
          ["Last Name", "last_name"],
          ["Email", "email"],
          ["Role", "roleName"],
          ["Status", "status"],
        ]}
        resourceSearchProps={["first_name", "last_name", "email"]}
        enablePagination
        enableRead={permCheck("read", pageName)}
        enableEdit={permCheck("update", pageName)}
        enableCallToAction={canCreate}
        searchInputPlaceholder="Search Users"
        callToActionContent="Add Users"
        renderReader={(user, handleClose) => (
            <Modal
              title="User Profile"
              handleClose={handleClose}
              handleSubmit={handleClose}
              dismissOnEsc={true}
              dismissOnBgClick={true}
              show={true}
            >
              <UserDetail {...user} />
            </Modal>
        )}

        /**
        * @todo improve the roleName === "Inspector" to get the data from the /roles call so that only those user mentioned in the role get to see the edit page
        * rest all will see the modal
        */

        renderEditor={(user, handleClose, users, editById, refetch) => {
          if (roleName === "Inspector") {
            return (
              ["BMP Contractor", "Client User"].includes(user.roleName) ?
                <Redirect
                  push
                  to={`/divisions/${appDivisionContext.appDivisionId}/user-profiles/${user.id}/edit`}
                />
                :
                <Modal
                  title="User Profile"
                  handleClose={handleClose}
                  handleSubmit={handleClose}
                  dismissOnEsc={true}
                  dismissOnBgClick={true}
                  show={true}
                >
                  <UserDetail {...user} />
                </Modal>
            )
          } else if (roleName === "Client Manager") {
            return (
              ["BMP Contractor", "Regulator", "Client User", "Inspector", "Client Manager","Client Inspector"].includes(user.roleName) ?
                <Redirect
                  push
                  to={`/divisions/${appDivisionContext.appDivisionId}/user-profiles/${user.id}/edit`}
                />
                :
                <Modal
                  title="User Profile"
                  handleClose={handleClose}
                  handleSubmit={handleClose}
                  dismissOnEsc={true}
                  dismissOnBgClick={true}
                  show={true}
                >
                  <UserDetail {...user} />
                </Modal>
            )
          }

          return (
            <Redirect
              push
              to={`/divisions/${appDivisionContext.appDivisionId}/user-profiles/${user.id}/edit`}
            />
          )
          // <>
          //   {console.log("users", user)}
          //   <EditUserModal
          //     userId={user.id}
          //     roleId={user.role_id}
          //     name={user.name}
          //     onClose={handleClose}
          //     onSubmit={updatedUser => {
          //       editById(user.id, updatedUser);
          //       refetch();
          //     }}
          //     onDelete={() => {
          //       const index = users.map(_ => _.id).indexOf(user.id);

          //       users.splice(index, 1);
          //       editById(user.id, false);
          //       handleClose();
          //     }}
          //   />
          // </>
        }}
        renderCallToAction={(handleClose, pushData) => (
          <AddUserModal
            onClose={handleClose}
            onSubmit={newUsers => {
              pushData(...newUsers);
              handleClose();
            }}
          />
        )}
        renderRowCell={(user, propName) => {
          switch (propName) {
            case "status":
              return (
                <UserStatus
                  user={user}
                  isBusy={showSpinner.includes(user.id)}
                  onInvite={() => {
                    if (permCheck("create", pageName)) {
                      selectUser(user);
                      setShowInvite(true);
                    }
                  }}
                  onToggleActivation={nextStatus => {
                    if (permCheck("update", pageName)) {
                      selectUser(user);
                      setShowActivation(true);
                    }
                  }}
                  canInvite={permCheck("create", pageName)}
                  canToggleActivation={permCheck("update", pageName)}
                />
              );
            default:
              return ResourceIndex.defaultProps.renderRowCell(user, propName);
          }
        }}
      />
      {
        /** invitation modal */
        <ConfirmationModal
          show={showInvite}
          title="Re-Invite User"
          subTitle={[
            `Are you sure you want to re-invite ${selectedName}?`,
            "Their previous invitations will no longer be valid.",
          ]}
          buttonText="Confirm"
          handleClose={e => {
            e.preventDefault();
            e.stopPropagation();
            setShowInvite(false);
            deselect();
          }}
          handleConfirm={() => {
            setShowSpinner([...showSpinner, selectedId]);
            setShowInvite(false);

            return userApi.invite(selectedId).then(() => {
              hideSpinner(selectedId, showSpinner);
              deselect();
            });
          }}
        />
      }
      {
        /** active/inactive modal */
        <ConfirmationModal
          show={showActivation}
          {...getActivationModalContent(selectedStatus, selectedName)}
          buttonText="Confirm"
          handleClose={e => {
            e.preventDefault();
            e.stopPropagation();
            setShowActivation(false);
            deselect();
          }}
          handleConfirm={() => {
            setShowSpinner([...showSpinner, selectedId]);
            setShowActivation(false);

            return userApi
              .toggleActivation(selectedId)
              .then(async ({ status }) => {
                const query = queryClient
                  .getQueryCache()
                  .getAll()
                  .filter(q => q.queryKey.includes("divisionUsers_"));

                if (query.length) {
                  query.map(({ queryKey }) =>
                    queryClient.invalidateQueries(queryKey),
                  );
                }

                hideSpinner(selectedId, showSpinner);
                getTeamManagementData()
                deselect();
              });
          }}
        />
      }
    </div>
  );
};

export default Users;
