import React, { useEffect, useState } from 'react';
import Logo from '../../../images/SW-Logo-White.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import QRCode from 'qrcode';
import Modal from 'react-modal';
import { useMediaQuery } from 'react-responsive';
import { saveAs } from 'file-saver';

const customModalStyles = {
  overlay: { backgroundColor: 'rgba(0,0,0,0.6)' },
  content: {
    display: 'grid',
    placeItems: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '40rem',
  },
};

const PublicURLNavbar = () => {
  const [buttonDisplayState, setButtonDisplayState] = useState(false);
  const [qrSrc, setQrSrc] = useState('');
  const [isUserValid, setIsUserValid] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const isTabletOrLess = useMediaQuery({ maxWidth: 768 });
  const [isCopied, setIsCopied] = useState(false);
  const qrData = `${window.location.origin}${window.location.pathname}`;

  useEffect(() => {
    const regex = /.*\/public\/..*\/inspection\/..*/;

    let regexExecuted = regex.exec(window.location.pathname);

    if (regexExecuted && regexExecuted.length === 1) {
      setButtonDisplayState(false);
    } else if (regexExecuted === null) {
      setButtonDisplayState(true);
    }
  }, [window.location.pathname]);

  useEffect(async () => {
    const hasToken = localStorage.getItem('Qr Access');

    setIsUserValid(hasToken ? true : false);
  }, []);

  useEffect(() => {
    let qrScale;

    if (isTabletOrLess) {
      customModalStyles.content.width = '80%';
      customModalStyles.content.maxHeight = 'none';
      qrScale = 4;
    } else {
      customModalStyles.content.maxHeight = 'fit-content';
      qrScale = 10;
    }

    QRCode.toDataURL(qrData, { scale: qrScale }).then((data) => setQrSrc(data));
  }, [isTabletOrLess]);

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(qrData)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className='public-url-navbar'>
        <img src={Logo} alt='logo' className='public-url-navbar__logo-img' />

        {isUserValid && buttonDisplayState ? (
          <button
            className='public-url-navbar__generate-qr-button'
            onClick={() => setModalIsOpen(true)}
          >
            Generate QR Code
          </button>
        ) : (
          <></>
        )}

        <Modal
          isOpen={modalIsOpen}
          shouldCloseOnOverlayClick={false}
          onRequestClose={() => setModalIsOpen(false)}
          style={customModalStyles}
        >
          <img
            src={qrSrc}
            alt='QR Code'
            className='public-url-navbar__qr-image'
          />
          <button
            className='public-url-navbar__close-button'
            onClick={() => setModalIsOpen(false)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <div className='public-url-navbar__copy-container'>
            <button
            className='primary public-url-navbar__download-button'
            onClick={() => saveAs(qrSrc, 'image.jpeg')}
          >
            Download QR
          </button>
            <button
              type='text'
              value={qrData}
              readOnly
              className='primary public-url-navbar__download-button'
              onClick={handleCopyClick}
            > Copy Link
              </button> 
          </div>
          <span>{isCopied ? 'copied!' : ''}</span>
        </Modal>
      </div>
    </>
  );
};

export default PublicURLNavbar;
