import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { positionableUtil } from '@sw-sw/common';
import classNames from 'classnames';
import moment from 'moment';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { RolesContext } from '../../../contexts/RolesContext';
import { dateFormat } from '../../../utils';
import findingUtil from '../../../utils/findings';
import InspectionContext from './../../../contexts/InspectionContext';
import ProjectContext from './../../../contexts/ProjectContext';
import { useClient } from '../../../hooks/client';
import FindingCheckbox from './FindingCheckbox';
import FindingImg from './FindingImg';

export const makeGoToDetailsAction =
  ({ history, isPublic }) =>
    (inspectionId, findingId) => {
      history.push(isPublic ? `/public/inspection/${inspectionId}/findings/${findingId}/details` : `/inspection/${inspectionId}/findings/${findingId}/details`);
    };

const getColor = (finding) => {
  let color = finding.color;

  if (!color) {
    if (
      finding.finding_type &&
      finding.finding_type.details &&
      finding.finding_type.details[0].color
    ) {
      color = finding.finding_type.details[0].color;
    } else {
      color = '#FF7272';
    }
  }

  return color;
};

export default function FindingCard({ finding }) {
  const {
    selectedFindings,
    readOnlyFindings,
    updateSelectedFindings,
    inspection,
  } = useContext(InspectionContext);
  const projectContext = useContext(ProjectContext);

  const clientQuery = useClient(projectContext.project.client_id);
  const findingUploads = finding.finding_uploads;
  const history = useHistory();
  const isPublic = history.location.pathname.startsWith("/public")
  const { userHasPermission } = useContext(RolesContext);
  const goToDetails = makeGoToDetailsAction({ history, isPublic });

  const canReadImages = userHasPermission('read', 'Finding Images');
  const dateCompleted = finding.date_completed
    ? moment.utc(finding.date_completed).format(dateFormat)
    : null;
  
    return (
    <div
      className={classNames('finding-wrapper pure-u pure-u-1 pure-u-md-1-2', {
        'finding-complete': finding.date_completed !== null,
      })}
      key={finding.id}
    >
      <div className='finding'>
        <div
          className='finding-content'
          onClick={() => {
            goToDetails(
              inspection.id ? inspection.id : finding.initial_inspection_id,
              finding.id,
            );
          }}
        >
          <div className='finding-text-info'>
            <h4>
              {!readOnlyFindings && !finding.date_completed && !isPublic && (
                <FindingCheckbox
                  checked={selectedFindings.includes(finding.id)}
                  handleClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    updateSelectedFindings(finding.id);
                  }}
                />
              )}
              <span
                className='tag pad-right'
                style={{
                  backgroundColor:
                    finding.type === 'MI' ||
                      finding.type === 'A' ||
                      finding.type === 'CA' ||
                      finding.color === null
                      ? findingUtil.getBGColor(finding.type, null)
                      : getColor(finding),
                }}
              >
                #{ finding.number }
              </span>
              
              <span>
                {finding.type === 'CA'
                  ? 'Corrective Action'
                  : finding.type === 'A'
                  ? 'Achievement'
                  : finding.type === 'MI'
                  ? 'Maintenance Item'
                  : finding.type}
              </span>
              {finding.date_completed ? (
                <FontAwesomeIcon
                  icon='check'
                  className='finding-card-checkmark'
                />
              ) : null}
            </h4>

            <div className='finding-content-comments'>
              {positionableUtil.findings.getCardLabel(finding).map((item) => (
                <p key={item}>{item}</p>
              ))}
            </div>

            {finding.InspectionFindingModel &&
              finding.InspectionFindingModel.carried_over ? (
              <span className='carry-over'>
                Carry Over <i className='fa fa-exclamation-circle' />
              </span>
            ) : null}

            <div className='start-end-dates'>
              <div>
                Initiated:
                <strong>
                  {moment.utc(finding.date_initiated).format(dateFormat)}
                </strong>
              </div>

              {finding.date_completed ? (
                <div>
                  Completed:
                  <strong>{dateCompleted}</strong>
                </div>
              ) : null}
            </div>
          </div>

          {clientQuery &&
            clientQuery.data &&
            !clientQuery.data.disable_images &&
            canReadImages &&
            findingUploads &&
            findingUploads.length ? (
            <FindingImg
              sources={findingUploads.map((fndUpl) => fndUpl.upload.GUID)}
              lightbox
              imageNumber={findingUploads.length}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
