import React, { useEffect, useContext, useState } from "react";
import { Redirect } from "react-router-dom";

import Loading from "../Shared/ResourceIndex/Loading";
import { ProjectContext } from "../../contexts/ProjectContext";
import projectApi from "../../utils/api/project";

/**
 * Top-level component to start the next inspection.
 *
 * @todo refactor {projectStore.startRoutineInspection} to not require project and inspections list
 */
function StartInspection({ match }) {
  const projectStore = useContext(ProjectContext);
  const [inspectionId, setInspectionId] = useState(null);

  useEffect(() => {
    // load the project
    projectApi.get(match.params.id).then(async project => {
      projectStore.setProject(project);
      projectStore.inspectionsQuery.refetch();
    });
  }, []);

  useEffect(() => {
    // get next inspection
    if (projectStore.inspectionsQuery.isFetched) {
      projectStore.startRoutineInspection().then(id => {
        setInspectionId(id);
      });
    }
  }, [projectStore.inspectionsQuery.isFetched, projectStore.project]);

  if (inspectionId) {
    return <Redirect push to={`/inspection/${inspectionId}/questionnaire`} />;
  }

  return <Loading />;
}

export default StartInspection;
